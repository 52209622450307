import React, { useState, useEffect } from "react";
import { CATEGORIES, QUALIFICATIONS } from "../../constants/constants";
import { FaUniversity } from "react-icons/fa";
import Select from "react-select"
import { TbCertificate } from "react-icons/tb";
import { MdEventSeat } from "react-icons/md";

const ExploreProgramsCard = ({ perks = false, type = "card" }) => {

  const [details, setDetails] = useState({
    vertical: null,
    qualification: null
  });
  const [redirectUrl, setRedirectUrl] = useState('');

  const handleSelectChange = (name, selectedOption) => {
    setDetails((prevData) => ({
      ...prevData,
      [name]: selectedOption
    }));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams();

    if (details.vertical) {
      queryParams.append('verticals', details.vertical.value);
    }

    if (details.qualification) {
      queryParams.append('qualification', details.qualification.value);
    }

    setRedirectUrl(queryParams.toString());
  }, [details]);

  const handleClickRedirect = () => {
    const baseUrl = `${process.env.REACT_APP_ACCREDIAN_SITE_URL}/programs/`;
    window.open(`${baseUrl}?${redirectUrl}`, '_blank');
  };


  return (
    <div className={`w-full ${type === "card" ? "p-8 bg-white border drop-shadow-sm rounded-xl" : ""}`}>
      {perks && (
        <div className="hidden md:flex gap-4 flex-wrap">
          <div className="flex gap-2 items-center px-4 py-2 rounded-lg bg-blue-50">
            <div className="w-6 h-6 flex-shrink-0">
              <FaUniversity className="w-full h-full" />
            </div>
            <h1 className="text-sm lg:text-lg font-medium">Degrees</h1>
          </div>
          <div className="flex gap-2 items-center px-4 py-2 rounded-lg bg-blue-50">
            <div className="w-6 h-6 flex-shrink-0">
              <TbCertificate className="w-full h-full" />
            </div>
            <h1 className="text-sm lg:text-lg font-medium">Certifications</h1>
          </div>
          <div className="flex gap-2 items-center px-4 py-2 rounded-lg bg-blue-50">
            <div className="w-6 h-6 flex-shrink-0">
              <MdEventSeat className="w-full h-full" />
            </div>
            <h1 className="text-sm lg:text-lg font-medium">Webinars</h1>
          </div>
          <div className="flex gap-2 items-center px-4 py-2 rounded-lg bg-blue-50">
            <div className="w-6 h-6 flex-shrink-0">
              <FaUniversity className="w-full h-full" />
            </div>
            <h1 className="text-sm lg:text-lg font-medium">Campus Immersions</h1>
          </div>
        </div>
      )}
      <div className="mt-6">
        <h1 className="font-semibold">Verticals</h1>
        <Select
          isClearable
          placeholder="Choose your interested vertical"
          value={details.vertical}
          onChange={(selectedOption) => handleSelectChange('vertical', selectedOption)}
          styles={{
            control: (provided, state) => ({
              ...provided,
              border: '2px solid rgb(212 212 212)',
              borderRadius: '8px',
              padding: '6px 0',
              outline: 'none',
              boxShadow: state.isFocused ? 'none' : provided.boxShadow,
              marginTop: "8px",
              textTransform: "capitalize",
              fontSize: '14px',
              [`@media (min-width: 600px)`]: {
                fontSize: '1rem',
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              textTransform: 'capitalize',
              fontSize: 'inherit', // Inherit font size from control
            }),
            option: (provided) => ({
              ...provided,
              textTransform: 'capitalize',
              fontSize: 'inherit', // Inherit font size from control
            }),
          }}
          options={CATEGORIES}
        />
      </div>
      <div className="mt-6">
        <h1 className="font-semibold">Highest Qualification</h1>
        <Select
          isClearable
          placeholder="Choose your highest qualification"
          value={details.qualification}
          onChange={(selectedOption) => handleSelectChange('qualification', selectedOption)}
          styles={{
            control: (provided, state) => ({
              ...provided,
              border: '2px solid rgb(212 212 212)',
              borderRadius: '8px',
              padding: '6px 0',
              outline: 'none',
              boxShadow: state.isFocused ? 'none' : provided.boxShadow,
              marginTop: "8px",
              textTransform: "capitalize",
              fontSize: '14px',
              [`@media (min-width: 600px)`]: {
                fontSize: '1rem',
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              textTransform: 'capitalize',
              fontSize: 'inherit', // Inherit font size from control
            }),
            option: (provided) => ({
              ...provided,
              textTransform: 'capitalize',
              fontSize: 'inherit', // Inherit font size from control
            }),
          }}
          options={QUALIFICATIONS}
        />
      </div>
      <div className="w-full mt-4">
        <button onClick={handleClickRedirect} className="px-4 py-2 rounded-md bg-universal text-white hover:bg-darkBlue text-base md:text-lg">
          Explore Programs
        </button>
      </div>
    </div>
  );
}

export default ExploreProgramsCard;
import { GiMaterialsScience } from "react-icons/gi";
import { PiStrategy } from "react-icons/pi";

export const CATEGORIES = [
  {
    label: "product management",
    value: "product management",
    category: [4, 20],
  },
  {
    label: "project management",
    value: "project management",
    category: [28],
  },
  {
    label: "data science",
    value: "data science",
    category: [2],
  },
  {
    label: "strategic management",
    value: "strategic management",
    category: [25],
  },
  {
    label: "digital Transformation",
    value: "digital Transformation",
    category: [19, 27],
  },
  {
    label: "business management",
    value: "business management",
    category: [2],
  },
  {
    label: "senior management",
    value: "senior management",
    category: [22],
  },
  {
    label: "fintech",
    value: "fintech",
    category: [24, 29],
  },
  {
    label: "business analytics",
    value: "business analytics",
    category: [11],
  },
  {
    label: "human resource",
    value: "human resource",
    category: [32],
  },
  {
    label: "cyber security",
    value: "cyber security",
    category: [17],
  }
];

export const QUALIFICATIONS = [
  {
    label: "High School",
    value: "highSchool",
  },
  {
    label: "Diploma",
    value: "diploma",
  },
  {
    label: "Bachelor's Degree",
    value: "bachelorDegree",
  },
  {
    label: "Master's Degree",
    value: "masterDegree",
  },
  {
    label: "Doctoral degree",
    value: "doctoralDegree",
  },
];

export const TABS = [
  {
    id: "my-courses",
    category: "My Courses",
    icon: GiMaterialsScience,
  },
  {
    id: "my-applications",
    category: "My Applications",
    icon: PiStrategy,
  },
];

export const APPLICATION_DATA = [
  {
    id: "ongoing-applications",
    title: "Ongoing Applications",
    applications: [],
  },
  {
    id: "completed-applications",
    title: "Completed Applications",
    applications: [],
  },
];

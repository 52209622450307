import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Link, useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import PolicyIcon from '@mui/icons-material/Policy';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import Logout from "@mui/icons-material/Logout";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Stack,
  Badge,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListSubheader,
  Popper,
  Collapse,
  ListItemIcon,
  ListItemButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

export default function Dashborednav(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [usercount, setUsercount] = useState("");
  const [usernames, setUsername] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setpopupOpen(true)
  };
  const [filepath, setFilepath] = useState(localStorage.getItem("profileURL") ? localStorage.getItem("profileURL") : "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp");
  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/profileDetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      if (res.data.status == "404") {
        localStorage.setItem("profileURL", "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp")
        setFilepath('https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp')
      } else {
        localStorage.setItem("profileURL", res.data[0].filename)
        setFilepath(res.data[0].filename)
      }
    });
  };
  useEffect(() => {
    if (!localStorage.getItem("profileURL")) {
      fetchData()
    }
  }, [localStorage.getItem("profile")])


  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");

  const LogOut = (name, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    // document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    // localStorage.removeItem("user_id");
    localStorage.clear();
    if (typeof window !== 'undefined') {
      window.location.href = "https://accredian.com"
    }
  };

  useEffect(() => {
    // //console.log(auth)
    var user_count = localStorage.getItem("count");
    var usernamest = localStorage.getItem("username");
    var lastname = localStorage.getItem("lastname");
    var email = localStorage.getItem("email");
    setUsercount(user_count);
    setUsername(usernamest);
    setLastname(lastname);
    setEmail(email);


    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);

  }, [props.showcoins]);

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar sx={{ background: "#000" }}>
          <Toolbar>
            <Grid item lg={12} container>
              <Grid item lg={2}>
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredainnew.webp"
                  alt="green iguana"
                  sx={{
                    display: { xs: "block", lg: "block" },
                    width: 100,
                    py: 1,
                  }}
                />
              </Grid>
              <Grid item lg={10}>
                <Box sx={{ ml: { xs: 14, lg: 0 } }}>
                  <Box
                    display="flex"
                    sx={{
                      float: "right",
                      border: "1px solid #fff",
                      borderRadius: "8px",
                      py: 0.7

                    }}

                    onClick={handleClick}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        marginBottom: "0.5rem !important",
                        mx: 2,
                        pt: 1,
                      }}
                    >
                      {usernames}
                    </Typography>


                    <CardMedia
                      component="img"
                      image={filepath}
                      alt="green iguana"
                      sx={{ width: 30, height: 30, borderRadius: "8px" }}
                    />

                    <ArrowDropDownIcon sx={{ mt: 0.5 }} />
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <Link
                      to="/Profile"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <MenuItem sx={{ color: "#000" }}>


                        <CardMedia
                          component="img"
                          image={filepath}
                          alt="green iguana"
                          sx={{ width: 30, height: 30, borderRadius: "8px" }}
                        />


                        <Typography sx={{ fontSize: "0.9rem", fontWeight: "bold", ml: 1, marginBottom: "0rem!important", mt: -2, display: 'inline-block' }}>{usernames} {lastname}</Typography>


                      </MenuItem>
                    </Link>
                    <MenuItem sx={{ mt: -4 }}>
                      <Typography sx={{ fontSize: "0.7rem", fontWeight: "bold", ml: 5, marginBottom: "0.0rem!important", display: 'inline-block' }}>{email}</Typography>
                    </MenuItem>

                    <Divider />
                    <Link
                      to="/Profile"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <MenuItem>
                        <ListItemIcon>
                          <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        My Profile
                      </MenuItem>
                    </Link>
                    {/* <Link
                    to={"/Policy/admissions-policy"}
                    style={{ textDecoration: "none", color: "#000" }}
                  > */}
                    <a href={`https://accredian.com/policies/accredian-policy`} style={{ textDecoration: "none", color: "#000" }} target="_blank">
                      <MenuItem>
                        <ListItemIcon>
                          <PolicyIcon fontSize="small" />
                        </ListItemIcon>
                        Student Policy
                      </MenuItem>
                      {/* </Link> */}
                    </a>
                    <Divider />
                    <Link
                      to="/ChangePassword"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <MenuItem sx={{ color: "#000" }}>
                        <ListItemIcon>
                          <SyncLockIcon fontSize="small" />
                        </ListItemIcon>
                        Change Password
                      </MenuItem>
                    </Link>

                    <MenuItem onClick={() => { LogOut('token', 'accredian.com') }}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import LoginDashboard from "../../Navbar/MobileSidebar";
// import Footer from "../../Footer/Footer";
import Sidbar from "../DesktopSidebar";
import MyProgramNav from "./MyProgramNav";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import axios from "axios";
import Swal from "sweetalert2";
const UpgradeProgram = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  const [program, setProgram] = useState("");
  const [programUp, setProgramUp] = useState("");
  const [pCode, setpcode] = useState("");
  const [checked1, setChecked1] = useState("");
  const [checked2, setChecked2] = useState("");
  const [checked3, setChecked3] = useState("");
  const [checked4, setChecked4] = useState("");
  const [checked5, setChecked5] = useState("");
  const [checked6, setChecked6] = useState("");

  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var program_id = localStorage.getItem("program_id");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
    setProgram(program_id);
  }, []);

  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  // if (user == null) {
  //  window.location.href="https://accredian.com"
  // }

  const handleChange1 = (e) => {
    const s = e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked1(e.target.checked)
  }
  const handleChange2 = (e) => {
    const s = e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked2(e.target.checked)
  }
  const handleChange3 = (e) => {
    const s = e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked3(e.target.checked)
  }
  const handleChange4 = (e) => {
    const s = e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked4(e.target.checked)
  }
  const handleChange5 = (e) => {
    const s = e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked5(e.target.checked)
  }
  const handleChange6 = (e) => {
    const s = e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked6(e.target.checked)
  }

  const handleSubmit = () => {
    const sendDataresult = {
      user_id: localStorage.getItem("user_id"),

      email: localStorage.getItem("email"),
      firstname: localStorage.getItem("username"),
      lastname: localStorage.getItem("lastname"),
      mobile: localStorage.getItem("mobile"),
      current_program: localStorage.getItem("program_name"),
      program: programUp,
      category: localStorage.getItem("category") < 3 ? "Data Science" : "Product Management",
      p_code: pCode

    };
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/programUpgrade/program-upgrade`,
        JSON.stringify(sendDataresult),
        options
      ).then((res) => {
        if (res.data.status == 200) {
          Swal.fire(
            'Upgrade Request Successfully Submitted!',
            'Thank you for raising a program upgrade request. Our Program Expert will contact you soon.',
            'success'
          )
        }
        else {
          Swal.fire(
            'Some error occured!',
            'Please contact Academics Team!',
            'error'
          )
        }

      })
  }

  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: { lg: 8, xs: 3 }, px: { xs: 2, lg: 0 } }}>
          <Grid container>
            <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box sx={{ ml: 3 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "0.2rem!important",
                  }}
                >
                  Pause My Programe
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", color: "#a1a5b7" }}>
                  Home
                  <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#a1a5b7", mx: 0.5 }}
                  />
                  Pause My Programe
                </Typography>
              </Box>
              <Box>
                <MyProgramNav />
              </Box>

              <Box sx={{ mb: 4 }}>
                {(() => {
                  if (category == "2" || category == "1") {
                    return (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "1.1rem",
                        }}
                      >
                        Step up your Data Science Journey with Advance concepts
                        in Machine Learning & AI
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "1.1rem",
                        }}
                      >
                        Step UP YOUR PRODUCT MANAGER JOURNEY WITH ADVANCED
                        CONCEPTS IN PRODUCT MANAGEMENT
                      </Typography>
                    );
                  }
                })()}
              </Box>
              <Box sx={{ mb: 4 }}>
                <Grid container>
                  {(() => {
                    if (program == "1" || program == "10") {
                      return (
                        <Grid
                          item
                          lg={4} md={6} sm={6} xs={12}

                          sx={{
                            maxWidth: { lg: "28.56% !important", md: "48.56% !important" },
                            ml: { lg: 2.05, md: 2.05 },
                            mb: 4,
                          }}
                        >
                          <Paper
                            sx={{
                              borderRadius: "8px",
                              boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                              p: 2,
                              height: "400px"
                            }}
                          >
                            <Box sx={{ height: "70px" }}>
                              <Typography
                                sx={{ textAlign: "center", fontWeight: "bold" }}
                              >
                                Upgrade to GCD
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                + 3 Terms covering ML Intermediate,Advanced,
                                Data Analytics with R & Data Visualization with
                                Tableau
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                1 Capstone Projects
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                Access to Industry Analytics Workshops -
                                Financial, Retail, Healthcare & Telecom
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                70% Program fee waiver scholarship
                              </Typography>
                            </Box>
                            <Box>
                              <FormGroup>
                                <center>
                                  {" "}
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                      },
                                      mt: 2,
                                    }}
                                    control={<Checkbox disabled={checked2 || checked3 || checked4 || checked5 || checked6} value={["Global Certificate in Data Science", "GCD"]} onChange={handleChange1} />}
                                    label={
                                      <Typography
                                        sx={{
                                          fontSize: "0.89rem",
                                          marginBottom: "0.8rem !important",
                                          mt: 2,
                                        }}
                                      >
                                        Select{" "}
                                      </Typography>
                                    }
                                  />
                                </center>
                              </FormGroup>
                            </Box>
                          </Paper>
                        </Grid>
                      );
                    }
                  })()}
                  {(() => {
                    if (
                      program == "1" ||
                      program == "2" ||
                      program == "10" ||
                      program == "11"
                    ) {
                      return (
                        <Grid
                          item
                          lg={4} md={6} sm={6} xs={12}
                          sx={{
                            maxWidth: { lg: "28.56% !important", md: "48.56% !important" },
                            ml: { lg: 2.05, md: 2.05 },
                            mb: 4,

                          }}
                        >
                          <Paper
                            sx={{
                              borderRadius: "8px",
                              boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                              p: 2,
                              height: "400px"
                            }}
                          >
                            <Box sx={{ height: "70px" }}>
                              <Typography
                                sx={{ textAlign: "center", fontWeight: "bold" }}
                              >
                                Upgrade to GCDAI
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                Advance GCD Terms + 6 Terms covering Tensorflow,
                                Keras, Natural Language Processing & Computer
                                Vision
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                2 Capstone Project
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                Access to Industry Analytics Workshops -
                                Financial, Retail, Healthcare &amp; Telecom
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                70% Program fee waiver scholarship
                              </Typography>
                            </Box>
                            <Box>
                              <FormGroup>
                                <center>
                                  {" "}
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                      },
                                      mt: 2,
                                    }}
                                    control={<Checkbox disabled={checked1 || checked3 || checked4 || checked5 || checked6} value={["Global Certificate in Data Science & AI", "GCDAI"]} onChange={handleChange2} />}
                                    label={
                                      <Typography
                                        sx={{
                                          fontSize: "0.89rem",
                                          marginBottom: "0.8rem !important",
                                          mt: 2,
                                        }}
                                      >
                                        Select{" "}
                                      </Typography>
                                    }
                                  />
                                </center>
                              </FormGroup>
                            </Box>
                          </Paper>
                        </Grid>
                      );
                    }
                  })()}
                  {(() => {
                    if (
                      program == "1" ||
                      program == "2" ||
                      program == "3" ||
                      program == "10" ||
                      program == "11" ||
                      program == "12"
                    ) {
                      return (
                        <Grid
                          item
                          lg={4} md={6} sm={6} xs={12}
                          sx={{
                            maxWidth: { lg: "28.56% !important", md: "48.56% !important" },
                            ml: { lg: 2.05, md: 2.05 },
                            mb: 4,
                          }}
                        >
                          <Paper
                            sx={{
                              // height: "380px",
                              borderRadius: "8px",
                              boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                              p: 2,
                              height: "400px"
                            }}
                          >
                            <Box sx={{ height: "70px" }}>
                              <Typography
                                sx={{ textAlign: "center", fontWeight: "bold" }}
                              >
                                Upgrade to PGP in Data Science and AI
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                Advance GCDAI Terms +3 months AI Fellowship
                                Track{" "}
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                3 Capstone Projects + Building AI Mode
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                Job Guarantee Add on Service
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                70% Program fee waiver scholarship
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 6.5 }}>
                              <FormGroup>
                                <center>
                                  {" "}
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                      },
                                      mt: 2,
                                    }}
                                    control={<Checkbox disabled={checked1 || checked2 || checked4 || checked5 || checked6} value={["PGP in Data Science and AI", "PGP-DSAI"]} onChange={handleChange3} />}
                                    label={
                                      <Typography
                                        sx={{
                                          fontSize: "0.89rem",
                                          marginBottom: "0.8rem !important",
                                          mt: 2,
                                        }}
                                      >
                                        Select{" "}
                                      </Typography>
                                    }
                                  />
                                </center>
                              </FormGroup>
                            </Box>
                          </Paper>
                        </Grid>
                      );
                    }
                  })()}
                  {(() => {
                    if (program == "16") {
                      return (
                        <Grid
                          item
                          lg={4} md={6} sm={6} xs={12}
                          sx={{
                            maxWidth: { lg: "28.56% !important", md: "48.56% !important" },
                            ml: { lg: 2.05, md: 2.05 },
                            mb: 4,
                          }}
                        >
                          <Paper
                            sx={{
                              // height: "380px",
                              height: "400px",
                              borderRadius: "8px",
                              boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                              p: 2,
                            }}
                          >
                            <Box sx={{ height: "70px" }}>
                              <Typography
                                sx={{ textAlign: "center", fontWeight: "bold" }}
                              >
                                Upgrade to GCPM
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                All CPM Terms + 3 Additional Terms covering
                                UI/UX, Product Engineering, Marketing & Launch.
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                Design Discovery Competition + Major Product
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                1-on-1 PM Career Guidancee
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                Product Management Career Launchpad
                              </Typography>
                            </Box>
                            <Box>
                              <FormGroup>
                                <center>
                                  {" "}
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                      },
                                      mt: 2,
                                    }}
                                    control={<Checkbox disabled={checked1 || checked2 || checked3 || checked5 || checked6} value={["Global Certificate in Product Management", "GCPM"]} onChange={handleChange4} />}
                                    label={
                                      <Typography
                                        sx={{
                                          fontSize: "0.89rem",
                                          marginBottom: "0.8rem !important",
                                          mt: 2,
                                        }}
                                      >
                                        Select{" "}
                                      </Typography>
                                    }
                                  />
                                </center>
                              </FormGroup>
                            </Box>
                          </Paper>
                        </Grid>
                      );
                    }
                  })()}
                  {(() => {
                    if (program == "16" || program == "17") {
                      return (
                        <Grid
                          item
                          lg={4} md={6} sm={6} xs={12}
                          sx={{
                            maxWidth: { lg: "28.56% !important", md: "48.56% !important" },
                            ml: { lg: 2.05, md: 2.05 },
                            mb: 4,
                          }}
                        >
                          <Paper
                            sx={{
                              height: "400px",
                              borderRadius: "8px",
                              boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                              p: 2,
                            }}
                          >
                            <Box sx={{ height: "70px" }}>
                              <Typography
                                sx={{ textAlign: "center", fontWeight: "bold" }}
                              >
                                Upgrade to PGP in PM
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                All GCPM Terms + 4 Additional Terms covering
                                Product Anaytics, Growth Strategies,
                                Monetization & Leadership.
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                Design Discovery Competition + Major Product +
                                Capstone Product
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                1-on-1 PM Career Guidancee
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                Product Management Career Launchpad
                              </Typography>
                            </Box>
                            <Box>
                              <FormGroup>
                                <center>
                                  {" "}
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                      },
                                      mt: 2,
                                    }}
                                    control={<Checkbox disabled={checked1 || checked2 || checked3 || checked4 || checked6} value={["PGP in Product Management", "PGP-PM"]} onChange={handleChange5} />}
                                    label={
                                      <Typography
                                        sx={{
                                          fontSize: "0.89rem",
                                          marginBottom: "0.8rem !important",
                                          mt: 2,
                                        }}
                                      >
                                        Select{" "}
                                      </Typography>
                                    }
                                  />
                                </center>
                              </FormGroup>
                            </Box>
                          </Paper>
                        </Grid>
                      );
                    }
                  })()}
                  {(() => {
                    if (program == "16" || program == "17" || program == "18") {
                      return (
                        <Grid
                          item
                          lg={4} md={6} sm={6} xs={12}
                          sx={{
                            maxWidth: { lg: "28.56% !important", md: "48.56% !important" },
                            ml: { lg: 2.05, md: 2.05 },
                            mb: 4,
                          }}
                        >
                          <Paper
                            sx={{
                              height: "400px",
                              borderRadius: "8px",
                              boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                              p: 2,
                            }}
                          >
                            <Box sx={{ height: "70px" }}>
                              <Typography
                                sx={{ textAlign: "center", fontWeight: "bold" }}
                              >
                                Upgrade to PGP in PM & DS
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                All Six GCPM terms plus Six Data Science Terms
                                covering Analytics & Machine Learning
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                All PM & Data Science Capstone Projects
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                1-on-1 PM & Data Science Career Guidance
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                              <Typography
                                sx={{
                                  fontSize: "0.75rem",
                                  marginBottom: "0.8rem !important",
                                  mx: 2,
                                }}
                              >
                                PM + Data Science Career Launchpa
                              </Typography>
                            </Box>
                            <Box>
                              <FormGroup>
                                <center>
                                  {" "}
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                      },
                                      mt: 2,
                                    }}
                                    control={<Checkbox disabled={checked1 || checked2 || checked3 || checked4 || checked5} value={["PGP in Product Managemet & Data Science", "PGP-PMDS"]} onChange={handleChange6} />}
                                    label={
                                      <Typography
                                        sx={{
                                          fontSize: "0.89rem",
                                          marginBottom: "0.8rem !important",
                                          mt: 2,
                                        }}
                                      >
                                        Select{" "}
                                      </Typography>
                                    }
                                  />
                                </center>
                              </FormGroup>
                            </Box>
                          </Paper>
                        </Grid>
                      );
                    }
                  })()}
                </Grid>
              </Box>
              <Box>
                {(() => {
                  if (category == "2" || category == "1") {
                    return (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "1.1rem",
                        }}
                      >
                        Talk to Machine Learning &amp; AI Program expert for
                        program selection &amp; upgrade options
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "1.1rem",
                        }}
                      >
                        Talk to Product management program expert for program
                        selection & upgrade options
                      </Typography>
                    );
                  }
                })()}
              </Box>
              <Box>
                <center>
                  {" "}
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!(checked1 || checked2 || checked3 || checked4 || checked5 || checked6)}
                    sx={{
                      mb: 0.3,
                      cursor: (checked1 || checked2 || checked3 || checked4 || checked5 || checked6) ? "pointer" : "not-allowed",
                      mr: 3,
                      background: "#009ef7",

                      borderRadius: "8px",
                      textTransform: "none",

                      "&:hover": {
                        background: "#009ef7",

                        borderRadius: "8px",
                        textTransform: "none",
                      },
                    }}
                  >
                    Request for Upgrade
                  </Button>
                </center>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default UpgradeProgram;

import { IoIosClose } from 'react-icons/io';
import React from "react";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import ExploreProgramsCard from './explore-programs-card';

export default function ExploreProgramsModal({ open, setOpen }) {

  const handleClose = () => setOpen(null);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open ? true : false}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {/* <Fade in={open ? true : false}> */}
          {/* <Box sx={{
            backgroundColor: "red",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
          }}> */}
          <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
            <div className='font-circular'>
              <div className='py-2 w-full text-center relative flex items-center justify-end'>
                {/* <h1 className="text-lg font-medium text-neutral-800 text-center">Don't miss the opportunities</h1> */}
                <div className='w-8 h-8 rounded-lg bg-white flex-shrink-0 cursor-pointer justify-self-end' onClick={handleClose}>
                  <IoIosClose className='w-full h-full' />
                </div>
              </div>
              <div className='bg-white xl:w-[50vw] lg:w-[70vw] md:w-[80vw] w-[90vw] md:min-h-[50vh] py-8 px-5 rounded-xl'>
                <h1 className="font-semibold text-neutral-600 text-center text-lg">Explore Programs</h1>
                {/* <div className='w-full'>
                  <div className="mt-6">
                    <h1 className="font-semibold text-sm">Verticals</h1>
                    <Select
                      isClearable
                      placeholder="Choose your interested vertical"
                      value={details.vertical}
                      onChange={(selectedOption) => handleSelectChange('vertical', selectedOption)}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: '2px solid rgb(212 212 212)',
                          borderRadius: '8px',
                          padding: '6px 0',
                          outline: 'none',
                          boxShadow: state.isFocused ? 'none' : provided.boxShadow,
                          marginTop: "8px",
                          textTransform: "capitalize",
                          fontSize: "14px"
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          textTransform: 'capitalize',
                        }),
                        option: (provided) => ({
                          ...provided,
                          textTransform: 'capitalize',
                        }),
                      }}
                      options={CATEGORIES}
                    />
                  </div>
                  <div className="mt-6">
                    <h1 className="font-semibold text-sm">Highest Qualification</h1>
                    <Select
                      isClearable
                      placeholder="Choose your highest qualification"
                      value={details.qualification}
                      onChange={(selectedOption) => handleSelectChange('qualification', selectedOption)} styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: '2px solid rgb(212 212 212)',
                          borderRadius: '8px',
                          padding: '6px 0',
                          outline: 'none',
                          boxShadow: state.isFocused ? 'none' : provided.boxShadow,
                          marginTop: "8px",
                          textTransform: "capitalize",
                          fontSize: "14px"

                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          textTransform: 'capitalize', // Capitalize selected value
                        }),
                        option: (provided) => ({
                          ...provided,
                          textTransform: 'capitalize', // Capitalize dropdown options
                        }),
                      }}
                      options={QUALIFICATIONS}
                    />
                  </div>
                  <div className="w-full mt-4">
                    <button onClick={handleClickRedirect} className="px-4 py-2 rounded-md bg-universal text-white hover:bg-darkBlue text-sm">
                      Explore Programs
                    </button>
                  </div>
                </div> */}
                <ExploreProgramsCard type='modal' />
              </div>
            </div>
          </div>
          {/* </Box> */}
        {/* </Fade> */}
      </Modal>
    </div>
  );
}



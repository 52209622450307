import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../../Navbar/MobileSidebar";
// import Footer from "../../Footer/Footer";
import Sidbar from "../DesktopSidebar";
import MyProgramNav from "./MyProgramNav";
import ByProgramNav from "./ByProgramNav";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import axios from "axios";
import Swal from "sweetalert2";
const Byprogram = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  const [program, setProgram] = useState("");
  const [programUp, setProgramUp] = useState("");
  const [pCode, setpcode] = useState("");
  const [checked1, setChecked1] = useState("");
  const [checked2, setChecked2] = useState("");
  const [checked3, setChecked3] = useState("");
  const [checked4, setChecked4] = useState("");
  const [checked5, setChecked5] = useState("");
  const [checked6, setChecked6] = useState("");
  
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const handleChange1=(e)=>{
    const s=e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked1(e.target.checked)
  }
  const handleChange2=(e)=>{
    const s=e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked2(e.target.checked)
  }
  const handleChange3=(e)=>{
    const s=e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked3(e.target.checked)
  }
  const handleChange4=(e)=>{
    const s=e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked4(e.target.checked)
  }
  const handleChange5=(e)=>{
    const s=e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked5(e.target.checked)
  }
  const handleChange6=(e)=>{
    const s=e.target.value.split(',')
    setProgramUp(s[0])
    setpcode(s[1])
    setChecked6(e.target.checked)
  }
  const newcategory =localStorage.getItem("category")
  if(newcategory=="7"){
    var programcategory ="Data Science";
    
  }
 else if(newcategory=="8"){
    var programcategory ="Product Management";
    
  }
  const handleSubmit=()=>{
    const sendDataresult = {
      user_id: localStorage.getItem("user_id"),
     
   email:localStorage.getItem("email"),
   firstname:localStorage.getItem("username"),
   lastname:localStorage.getItem("lastname"),
   mobile:localStorage.getItem("mobile"),
  //  current_program:localStorage.getItem("program_name"),
   program:programUp,
   category:programcategory,
   p_code:pCode
     
    };
    axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/program_purchase/program-purchase`,
      JSON.stringify(sendDataresult),
      options
    ).then((res)=>{
     if(res.data.status==200){
      Swal.fire(
        'Upgrade Request Successfully Submitted!',
        'Thank you for raising a program upgrade request. Our Program Expert will contact you soon.',
        'success'
      )
     }
     else{
      Swal.fire(
        'Some error occured!',
        'Please contact Academics Team!',
        'error'
      )
     }
     
    })
  }
  let navigate = useNavigate();
  useEffect(() => {
    // //console.log(auth)
    // //console.log("called")
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var program_id = localStorage.getItem("program_id");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
    setProgram(program_id);
  }, []);

  // if (user == null) {
  //  window.location.href="https://accredian.com"
  // }
  // //console.log(program,"pid")
  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: { lg: 8, xs: 3 }, px: { xs: 2, lg: 0 } }}>
          <Grid container>
            <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "0.2rem!important",
                  }}
                >
                 Buy Program
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", color: "#a1a5b7" }}>
                  Hone
                  <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#a1a5b7", mx: 0.5 }}
                  />
                 Buy Program
                </Typography>
              </Box>
              <Box>
                <ByProgramNav />
              </Box>

              <Box sx={{ mb: 4 }}>
                {(() => {
                  if (category == "7") {
                    return (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}
                      >
                        STEP UP YOUR DATA SCIENCE JOURNEY WITH ADVANCE CONCEPTS
                        IN MACHINE LEARNING & AI
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "1rem",
                          mr:8,
                        }}
                      >
                        STEP UP YOUR PRODUCT MANAGER JOURNEY WITH ADVANCED
                        CONCEPTS IN PRODUCT MANAGEMENT
                      </Typography>
                    );
                  }
                })()}
              </Box>
              <Box sx={{ mb: 4 }}>
                <Grid container>
                  {(() => {
                    if (program == "28") {
                      return (
                        <>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            sx={{
                              maxWidth: { lg: "28.56% !important" },
                              ml: { lg: 2.05 },
                              mb: 4,
                            }}
                          >
                            <Paper
                              sx={{
                                borderRadius: "8px",
                                boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                                p: 2,
                              }}
                            >
                              <Box sx={{ height: "70px" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Buy 3 Month Program: CDF
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  Learn the basics of Data science through
                                  Practical application.
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  Develop deeper understanding of Exploratory
                                  Data Analysis, Data Visualization & Data
                                  Storytelling.
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  1-on-1 Career Guidance
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  4-week-long Data Science Career Launchpad
                                  Program
                                </Typography>
                              </Box>
                              <Box sx={{ mt: 2 }}>
                              <FormGroup>
                                <center>
                                  {" "}
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                      },
                                      mt: 2,
                                    }}
                                    control={<Checkbox disabled={checked2||checked3||checked4||checked5||checked6} value={["Certificate in Data Science Foundation","CDF"]} onChange={handleChange1} />}
                                    label={
                                      <Typography
                                        sx={{
                                          fontSize: "0.89rem",
                                          marginBottom: "0.8rem !important",
                                          mt: 2,
                                        }}
                                      >
                                        Select{" "}
                                      </Typography>
                                    }
                                  />
                                </center>
                              </FormGroup>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            sx={{
                              maxWidth: { lg: "28.56% !important" },
                              ml: { lg: 2.05 },
                              mb: 4,
                            }}
                          >
                            <Paper
                              sx={{
                                borderRadius: "8px",
                                boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                                p: 2,
                              }}
                            >
                              <Box sx={{ height: "70px" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Buy 6 Month Program: GCD
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  CDF Terms + Concepts of machine learning
                                  through practical application.
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  Develop deeper understanding of Machine
                                  learning algorithms, Model Evaluation
                                  Techniques & Hyperparameter tuning Techniques
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  1-on-1 Career Guidance
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  4-week-long Data Science Career Launchpad
                                  Program
                                </Typography>
                              </Box>
                              <Box>
                              <FormGroup>
                                <center>
                                  {" "}
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                      },
                                      mt: 2,
                                    }}
                                    control={<Checkbox disabled={checked2||checked3||checked4||checked5||checked6} value={["Global Certificate in Data Science","GCD"]} onChange={handleChange2} />}
                                    label={
                                      <Typography
                                        sx={{
                                          fontSize: "0.89rem",
                                          marginBottom: "0.8rem !important",
                                          mt: 2,
                                        }}
                                      >
                                        Select{" "}
                                      </Typography>
                                    }
                                  />
                                </center>
                              </FormGroup>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            sx={{
                              maxWidth: { lg: "28.56% !important" },
                              ml: { lg: 2.05 },
                              mb: 4,
                            }}
                          >
                            <Paper
                              sx={{
                                height: "380px",
                                borderRadius: "8px",
                                boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                                p: 2,
                              }}
                            >
                              <Box sx={{ height: "70px" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Buy 12 Month Program: PGP-DS
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  GCD Terms + Concepts of Artificial
                                  intelligence, Computer Vision & Natural
                                  Language Processing
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  Learn to build object detection models,
                                  language translator, Chat bot, Image
                                  segmentation models
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  Job Guarantee
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  4-week-long Data Science Career Launchpad
                                  Program
                                </Typography>
                              </Box>
                              <Box sx={{ mt: -0.6 }}>
                                <FormGroup>
                                  <center>
                                    {" "}
                                    <FormControlLabel
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                        mt: 2,
                                      }}
                                      control={<Checkbox disabled={checked1||checked2||checked4||checked5||checked6} value={["PGP in Data Science","PGP-DS"]} onChange={handleChange3} />}
                                      label={
                                        <Typography
                                          sx={{
                                            fontSize: "0.89rem",
                                            marginBottom: "0.8rem !important",
                                            mt: 2,
                                          }}
                                        >
                                          Select{" "}
                                        </Typography>
                                      }
                                    />
                                  </center>
                                </FormGroup>
                              </Box>
                            </Paper>
                          </Grid>
                        </>
                      );
                    }
                  })()}

                  {(() => {
                    if (program == "27") {
                      return (
                        <>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            sx={{
                              maxWidth: { lg: "28.56% !important" },
                              ml: { lg: 2.05 },
                              mb: 4,
                            }}
                          >
                            <Paper
                              sx={{
                                height: "380px",
                                borderRadius: "8px",
                                boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                                p: 2,
                              }}
                            >
                              <Box sx={{ height: "70px" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Buy 5 Months Program: ACPM
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                               Acquire fundamental knowledge of Product Management through hands-on experience
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                 Cultivate a more profound grasp of Product Strategy, User-Centered Design & Marketing basics
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                 1-on-1 Career Guidance
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                 4-week-long Product Management Career Launchpad Program
                                </Typography>
                              </Box>
                              <Box>
                                <FormGroup>
                                  <center>
                                    {" "}
                                    <FormControlLabel
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                        mt: 2,
                                      }}
                                      control={<Checkbox disabled={checked1||checked2||checked3||checked5||checked6} value={["IITG Advanced Certification in Product Management","ACPM"]} onChange={handleChange4} />}
                                      label={
                                        <Typography
                                          sx={{
                                            fontSize: "0.89rem",
                                            marginBottom: "0.8rem !important",
                                            mt: 2,
                                          }}
                                        >
                                          Select{" "}
                                        </Typography>
                                      }
                                    />
                                  </center>
                                </FormGroup>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            sx={{
                              maxWidth: { lg: "28.56% !important" },
                              ml: { lg: 2.05 },
                              mb: 4,
                            }}
                          >
                            <Paper
                              sx={{
                                height: "380px",
                                borderRadius: "8px",
                                boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                                p: 2,
                              }}
                            >
                              <Box sx={{ height: "70px" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                 Buy 8 Months Program: EPPM
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                 ACPM Terms + Key Concepts of Product Monetization
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  Develop a deeper understanding of Product Growth, Monetization & Leadership
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                 1-on-1 Career Guidance
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                  4-week-long Product Management Career Launchpad Program
                                </Typography>
                              </Box>
                              <Box>
                                <FormGroup>
                                  <center>
                                    {" "}
                                    <FormControlLabel
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                        mt: 4.5,
                                      }}
                                      control={<Checkbox disabled={checked1||checked2||checked3||checked4||checked6} value={["IITG Advanced Certification in Product Management","EPPM"]} onChange={handleChange5} />}
                                      label={
                                        <Typography
                                          sx={{
                                            fontSize: "0.89rem",
                                            marginBottom: "0.8rem !important",
                                            mt: 2,
                                          }}
                                        >
                                          Select{" "}
                                        </Typography>
                                      }
                                    />
                                  </center>
                                </FormGroup>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            sx={{
                              maxWidth: { lg: "28.56% !important" },
                              ml: { lg: 2.05 },
                              mb: 4,
                            }}
                          >
                            <Paper
                              sx={{
                                height: "380px",
                                borderRadius: "8px",
                                boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                                p: 2,
                              }}
                            >
                              <Box sx={{ height: "70px" }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                 Buy 11 Months Program: EPPMDS
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                ACPM Terms + Key Concepts of Data Analysis, EDA & Machine Learning
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                 Learn to build Features, Dashboards & Classification models
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                1-on-1 Career Guidance
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <DoubleArrowIcon sx={{ fontSize: "0.85rem" }} />
                                <Typography
                                  sx={{
                                    fontSize: "0.75rem",
                                    marginBottom: "0.8rem !important",
                                    mx: 2,
                                  }}
                                >
                                4-week-long Product Management Career Launchpad Program
                                </Typography>
                              </Box>
                              <Box sx={{mt:4}}>
                                <FormGroup>
                                  <center>
                                    {" "}
                                    <FormControlLabel
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                        mt: 2,
                                      }}
                                      control={<Checkbox disabled={checked1||checked2||checked3||checked5||checked4} value={["IIT G Executive Program in Product Management and Data Science","EPPMDS"]} onChange={handleChange6} />}
                                      label={
                                        <Typography
                                          sx={{
                                            fontSize: "0.89rem",
                                            marginBottom: "0.8rem !important",
                                            mt: 2,
                                          }}
                                        >
                                          Select{" "}
                                        </Typography>
                                      }
                                    />
                                  </center>
                                </FormGroup>
                              </Box>
                            </Paper>
                          </Grid>
                        </>
                      );
                    }
                  })()}
                </Grid>
              </Box>
              <Box>
                {(() => {
                  if (category == "7") {
                    return (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}
                      >
                        Talk to Machine Learning &amp; AI Program expert for
                        program selection &amp; upgrade options
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}
                      >
                        Talk to Product management program expert for detailed
                        program information
                      </Typography>
                    );
                  }
                })()}
              </Box>
              <Box>
                <center>
                  {" "}
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!(checked1||checked2||checked3||checked4||checked5||checked6)}
                    sx={{
                      mb: 0.3,
                      cursor:(checked1||checked2||checked3||checked4||checked5||checked6)?"pointer":"not-allowed",
                      mr: 3,
                      background: "#009ef7",

                      borderRadius: "8px",
                      textTransform: "none",

                      "&:hover": {
                        background: "#009ef7",

                        borderRadius: "8px",
                        textTransform: "none",
                      },
                    }}
                  >
                    Request for Upgrade
                  </Button>
              
                </center>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Byprogram;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import PersonIcon from "@mui/icons-material/Person";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import PolicyIcon from "@mui/icons-material/Policy";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import Logout from "@mui/icons-material/Logout";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import "../Dashboard/dashboard.css";
import { encode as btoa } from "base-64";
import {
  Typography,
  Button,
  Box,
  Grid,
  CardMedia,
  Stack,
  Badge,
  List,
  ListItem,
  ListItemText,
  Popper,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Backdrop,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { MdFeedback, MdOutlineLockClock } from "react-icons/md";
import DropDown from "../Dashboard/Sidebar/DropDown/DropDown";
import sidebarData from "../../Assets/constants/sidebar.json"
import PID_MAP from "../../Assets/constants/inclusion_pid.json"
import ShareFeedback from "../Dashboard/Sidebar/Modals/share-feedback";
import Feedback from "../Dashboard/Sidebar/Modals/feedback";


const drawerWidth = 320;

const Category = [
  {
    value: "faculty",
    label: "Faculty",
  },
  {
    value: "material",
    label: "Material",
  },
  {
    value: "quizzes_assignments",
    label: "Quizzes/Assignments",
  },
  {
    value: "projects",
    label: "Projects",
  },
  {
    value: "others",
    label: "Others",
  },
];
function LoginDashboard(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [usercount, setUsercount] = useState("");
  const [usernames, setUsername] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [coin, setCoindata] = useState(localStorage.getItem("coins") ? localStorage.getItem("coins") : "");
  const [notifiction, setNotifictiondata] = useState("");
  const [countnoti, setCountnoti] = useState("");
  const [noanchorEl, setNoanchorEl] = useState(null);
  const [mopen, setMopen] = useState(false);
  const [sharefeedback, setSharefeedback] = useState("");
  const [otherfeedbackdes, setOtherfeedbackdes] = useState("");
  const [feedbackdes, setFeedbackdes] = useState("");
  const [openloder, setOpenloder] = useState("");
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [feddbackopen, setFeddbackopen] = useState();
  const [shreefeddbackopen, setFeddbackopenshree] = useState();
  const [openrc, setOpenrc] = useState(false);
  const [openpp, setOpenpp] = useState(false);
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  const [term, setTerm] = useState("");
  const [filepath, setFilepath] = useState(localStorage.getItem("profileURL") ? localStorage.getItem("profileURL") : "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp");
  const newemail = btoa(localStorage.getItem("email"));

  // const { window } = props;
  const LoaderClose = () => {
    setOpenloder(false);
  };
  const LoaderOpen = () => {
    setOpenloder(true);
  };
  const submitForm = async (e) => {
    LoaderOpen();
    e.preventDefault();

    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      enrol_id: localStorage.getItem("enrol_id"),
      message: feedbackdes,
      category: localStorage.getItem("category")
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/facultyFeedback/faculty-feedback`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == 200) {
          Swal.fire({
            title: "Success",
            text: "Thank you",
            icon: "success",

            confirmButtonText: "OK",
          });
        }

        LoaderClose();
        handleClose();
      });
    //console.log(sandData);
  };
  const ShareFeedbacksubmitForm = async (e) => {
    LoaderOpen();
    e.preventDefault();

    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      user_id: localStorage.getItem("user_id"),
      program_id: localStorage.getItem("program_id"),
      batch_id: localStorage.getItem("batch_id"),
      term_id: localStorage.getItem("term"),
      feedback_for: isShown,
      feedback: feedbackdes,
      faculty_id: sharefeedback.faculty_id,
      faculty_email: sharefeedback.faculty_email,
      faculty_name: sharefeedback.faculty_name,
      username: sharefeedback.username,
      program_name: sharefeedback.program_name,
      batch_name: sharefeedback.batch_name,
      term_name: sharefeedback.term_name,
      user_email: sharefeedback.user_email,
      other_feedback: otherfeedbackdes,

    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/feedback/studentnew-feedback`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        LoaderClose()
        if (result.data.status == 200) {
          Swal.fire({
            title: "Thank You",
            text: "Your Feedback has been successfully sent.",
            icon: "success",

            confirmButtonText: "OK",
          });
        }
        else {
          Swal.fire({
            title: "Error",
            text: "There was an issue sending your feedback. Please try again after few moments.",
            icon: "error",

            confirmButtonText: "OK",
          });
        }
        setFeedbackdes("");
        LoaderClose();
        handleClose();
        setIsShown(false);
        setOtherfeedbackdes("");
      });

  };
  const feedbackdeschnage = (event) => {
    setFeedbackdes(event.target.value);
  };
  const otherfeedbackdeschnage = (event) => {
    setOtherfeedbackdes(event.target.value);
  };
  const sharefeedbackdetails = async () => {
    LoaderOpen()
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetch_share_feedback_details`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
      },
    }).then((res) => {
      LoaderClose()
      setSharefeedback(res.data);
      if (res.data.faculty_email) {
        setFeddbackopenshree(true);
      }
      else {
        Swal.fire({
          title: "Info",
          text: "Faculty is not assigned for the current term",
          icon: "info",
          confirmButtonText: "Ok"
        })
      }
    });
  };
  const notifictionClick = (event) => {
    // console.log(event.currentTarget,"ll")
    fetchnotifictiondata()
    setNoanchorEl(noanchorEl ? null : event.currentTarget);
  };
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  // const container =
  //   window !== undefined ? () => window().document.body : undefined;
  const opens = Boolean(noanchorEl);
  const id = opens ? "simple-popper" : undefined;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopup = () => {
    setAnchorEl(null);

  };
  const handleClose = () => {
    setFeddbackopen(false);
    setFeddbackopenshree(false);
  };
  let navigate = useNavigate();


  // const handleClicknew = () => {
  //   setOpen(!open);
  // };
  const mhandleClick = () => {
    setMopen(!mopen);
  };
  const handleRcClick = () => {
    setOpenrc(!openrc);
  };
  const handlePpClick = () => {
    setOpenpp(!openpp);
  };
  const handleClickOpen = () => {
    setFeddbackopen(true);
  };

  const handleClosenew = () => {
    setFeddbackopen(false);
    setFeddbackopenshree(false);
  };
  const shreehandleClickOpen = () => {
    sharefeedbackdetails();

  };
  const [isShown, setIsShown] = useState(false);
  const otherClick = (e) => {
    setIsShown(e.target.value);
  };
  const LogOut = (name, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    localStorage.clear();
    window.location.href = "https://accredian.com"
  };
  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/profileDetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      if (res.data.status == "404") {
        localStorage.setItem("profileURL", "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp")
        setFilepath('https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp')
      } else {
        localStorage.setItem("profileURL", res.data[0].filename)
        setFilepath(res.data[0].filename)
      }
    });
  };
  useEffect(() => {
    if (!localStorage.getItem("profileURL")) {
      fetchData()
    }
  }, [localStorage.getItem("profile")])

  const checkdclick = async (id) => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/updatenotification_status`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
        id: id,
      },
    }).then((res) => {
      setCountnoti(res.data.unchecked_count);
    });
  };
  const fetchcoindata = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetchstudentcoins`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
      },
    }).then((res) => {
      if (res.data.status == 300) {
        setCoindata(0);
      }
      else {
        localStorage.setItem("coins", res.data)
        setCoindata(res.data);
      }
    });
  };

  const fetchnotifictiondata = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetchnotification`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
      },
    }).then((res) => {
      setNotifictiondata(res.data.notification);
      setCountnoti(res.data.unchecked_count);
    });
  };
  useEffect(() => {
    // //console.log(auth)
    var user_count = localStorage.getItem("count");
    var usernamest = localStorage.getItem("username");
    var lastname = localStorage.getItem("lastname");
    var email = localStorage.getItem("email");
    var term = localStorage.getItem("term");
    setTerm(term);
    setUsercount(user_count);
    setUsername(usernamest);
    setLastname(lastname);
    setEmail(email);
    if (!localStorage.getItem("coins")) {
      fetchcoindata();
    }

    // fetchnotifictiondata();
    // certificationExam();

    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
  }, [props.showcoins]);
  const newpolicy = localStorage.getItem("category");
  const newpolicyprogram = localStorage.getItem("program_id");

  if (newpolicy == "2" || newpolicy == "4" || newpolicy == "1" || newpolicy == "3" || newpolicy == "16") {
    var policylink = "accredian-policy";
  }
  else if (newpolicy == "9" || newpolicy == "13" || newpolicy == "14" || newpolicy == "21") {
    var policylink = "xlri-delhi-policy";
  }
  else if (newpolicyprogram == "29" || newpolicyprogram == "33" || newpolicyprogram == "34" || newpolicyprogram == "35" || newpolicyprogram == "41" || newpolicyprogram == "42" || newpolicyprogram == "43" || newpolicyprogram == "44" || newpolicyprogram == "46" || newpolicyprogram == "47" || newpolicyprogram == "75" || newpolicyprogram == "76" || newpolicyprogram == "77" || newpolicyprogram == "78" || newpolicyprogram == "79") {
    var policylink = "iitg-policy";
  }
  else if (newpolicy == "12" || newpolicy == "10") {
    var policylink = "imi-admissions-policy";
  }
  else if (newpolicy == "15") {
    var policylink = "spj-policy";
  }
  else if (newpolicy == "18" || newpolicy == "20" || newpolicy == "22" || newpolicy == "24" || newpolicy == "25") {
    var policylink = "iimv-policy";
  }
  else if (newpolicy == "19") {
    var policylink = "spj-policy";
  }
  // else if (newpolicy == "26") {
  //   var policylink = "spj-policy";
  // }
  else {
    var policylink = "accredian-policy";
  }

  const program_inclusion_pid = localStorage.getItem("program_id");
  const program_inclusive_id = PID_MAP[program_inclusion_pid];

  const [sidebarCategory, setSidebarCategory] = useState("other");
  const [cols, setCols] = useState([]);

  useEffect(() => {
    let selectedCategory = localStorage.getItem("category") || "other";
    setSidebarCategory(selectedCategory);

    let filteredData = sidebarData.filter(col => col.categories.includes(selectedCategory));
    setCols(filteredData);

    if (filteredData.length === 0) {
      selectedCategory = "other";
      setSidebarCategory(selectedCategory);
      filteredData = sidebarData.filter(col => col.categories.includes(selectedCategory));
      setCols(filteredData);
    }
  }, []);


  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box display="flex" alignItems="center">
        <Link to="/" style={{ textDecoration: "none" }}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
            alt="green iguana"
            sx={{
              display: { xs: "block", lg: "block" },
              width: 120,
              py: 1,
              ml: 2.5,
            }}
          />
        </Link>
        <ClearIcon sx={{ ml: 18 }} onClick={handleDrawerToggle} />
      </Box>
      <Divider />
      <>
        <div className="w-full flex flex-col gap-2 px-2 py-2 overflow-y-auto custom-scrollbar">
          {cols?.map((col, index) => (
            <div key={index} className="w-full">
              {col.type === "link" ? (
                <Link to={col.link}>
                  <div className={`py-2 px-2 rounded flex items-center justify-start text-start gap-2 hover:bg-[#E6F2FF] hover:text-black transition-all duration-150 ${pathname === col.link ? "bg-[#E6F2FF] text-black" : "text-[#7e8299]"}`}>
                    <div className="w-6 h-6 flex-shrink-0">
                      <img className="w-full h-full object-contain" src={pathname === col.link ? col?.active : col?.icon} alt={col.title} />
                    </div>
                    <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                  </div>
                </Link>
              ) : col.type === "external" ? (
                <a target="_blank" rel="noreferrer" href={`${col.link}/${email}`}>
                  <div className="flex items-center gap-2 py-2 px-2 text-start bg-white  text-neutral-500 hover:bg-[#E6F2FF] hover:text-black transition-all duration-150 rounded">
                    <div className="w-6 h-6 flex-shrink-0">
                      <img className="w-full h-full object-contain" src={col?.icon} alt={col.title} />
                    </div>
                    <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                  </div>
                </a>
              ) : col.type === "inclusion" ? (
                <a target="_blank" rel="noreferrer" href={`${col.link}/${program_inclusive_id}`}>
                  <div className="flex items-center gap-2 py-2 px-2 text-start bg-white  text-neutral-500 hover:bg-[#E6F2FF] hover:text-black transition-all duration-150 rounded">
                    <div className="w-6 h-6 flex-shrink-0">
                      <img className="w-full h-full object-contain" src={col?.icon} alt={col.title} />
                    </div>
                    <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                  </div>
                </a>
              ) : col.type === "dropdown" ? (
                <DropDown pathname={pathname} col={col} />
              ) : col.type === "modal" ? (
                <button onClick={() => setIsModalOpen("share-feedback")} className="text-sm w-full text-start">
                  <div className="flex items-center gap-2 py-2 px-2 bg-white rounded hover:bg-[#E6F2FF] hover:text-black transition-all duration-150  text-neutral-500">
                    <div className="w-6 h-6 flex-shrink-0">
                      <img className="w-full h-full object-contain" src={col?.icon} alt={col.title} />
                    </div>
                    <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                  </div>
                </button>
              ) : (
                <button className="text-sm w-full text-start" disabled>
                  <div className="flex items-center gap-2 justify-between py-2 px-2 bg-white rounded  text-neutral-500 hover:bg-[#E6F2FF] hover:text-black transition-all duration-150">
                    <div className="flex gap-2 items-center">
                      <div className="w-6 h-6 flex-shrink-0">
                        <img className="w-full h-full object-contain" src={col?.icon} alt={col.title} />
                      </div>
                      <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                    </div>
                    <MdOutlineLockClock size={20} className="cursor-pointer" title="Coming Soon" />
                  </div>
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="w-full flex items-center justify-end">
          <button onClick={() => setIsModalOpen("feedback")} className="px-4 py-2 flex gap-2 items-center font-semibold capitalize text-neutral-500">
            <MdFeedback className="text-red-400" size={20} />
            feedback
          </button>
        </div>

      </>
    </Box>
  );
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        sx={{
          background: "#000",
          paddingRight: "0px!important",
          height: { xs: "60px", lg: "65px", sm: "65px", md: "65px" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none", sm: "block" }, color: "#fff" }}
          >
            <MenuIcon />
          </IconButton>
          <Grid item lg={12} container>
            <Grid item lg={2}>
              <Box display="flex">
                <>
                  <CardMedia
                    component="img"
                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredainnew.webp"
                    alt="green iguana"
                    sx={{
                      display: { xs: "none", lg: "block" },
                      width: 100,
                      py: "11px",
                      objectFit: "unset",
                    }}
                  />

                  <Link to="/">
                    <Box
                      onClick={() => { localStorage.removeItem("codData"); localStorage.removeItem("showCOD"); localStorage.removeItem("coins"); localStorage.removeItem("courseDetails") }}
                      display="flex"
                      sx={{
                        float: "right",
                        border: "1px solid #fff",
                        borderRadius: "8px",
                        py: 0.4,
                        px: 1,
                        ml: { lg: 3, xs: 0 },
                        mt: 0.7,
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          marginBottom: "0.5rem !important",
                          mx: 2,
                          pt: 1,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        Home
                      </Typography>
                    </Box>
                  </Link>
                </>
              </Box>
            </Grid>
            <Grid item lg={10}>
              <Box>
                <Box
                  display="flex"
                  sx={{
                    float: "right",
                    border: "1px solid #fff",
                    borderRadius: "8px",
                    py: 0.8,
                    mt: 0.6,
                  }}
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      marginBottom: "0.5rem !important",
                      mx: 2,
                      pt: 1,
                    }}
                  >
                    {usernames}
                  </Typography>


                  <CardMedia
                    component="img"
                    image={filepath}
                    alt="green iguana"
                    sx={{ width: 30, height: 30, borderRadius: "8px" }}
                  />


                  <ArrowDropDownIcon sx={{ mt: 0.5 }} />
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClosePopup}
                  onClick={handleClosePopup}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Link
                    to="/Profile"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MenuItem sx={{ color: "#000" }}>


                      <CardMedia
                        component="img"
                        image={filepath}
                        alt="green iguana"
                        sx={{
                          width: 30,
                          height: 30,
                          borderRadius: "8px",
                        }}
                      />



                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          ml: 1,
                          marginBottom: "0rem!important",
                          mt: -2,
                          display: "inline-block",
                        }}
                      >
                        {usernames} {lastname}
                      </Typography>
                    </MenuItem>
                  </Link>
                  <MenuItem sx={{ mt: -4 }}>
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                        ml: 5,
                        marginBottom: "0.0rem!important",
                        display: "inline-block",
                      }}
                    >
                      {email}
                    </Typography>
                  </MenuItem>

                  <Divider />
                  <Link
                    to="/Profile"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MenuItem>
                      <ListItemIcon>
                        <PersonIcon fontSize="small" />
                      </ListItemIcon>
                      My Profile
                    </MenuItem>
                  </Link>

                  {/* <Link
                    to={`/Policy/${policylink}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  > */}
                  <a href={`https://accredian.com/policies/${policylink}`} style={{ textDecoration: "none", color: "#000" }} target="_blank">
                    <MenuItem>
                      <ListItemIcon>
                        <PolicyIcon fontSize="small" />
                      </ListItemIcon>
                      Student Policy
                    </MenuItem>
                  </a>
                  <Divider />
                  <Link
                    to="/ChangePassword"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MenuItem sx={{ color: "#000" }}>
                      <ListItemIcon>
                        <SyncLockIcon fontSize="small" />
                      </ListItemIcon>
                      Change Password
                    </MenuItem>
                  </Link>

                  <MenuItem onClick={() => { LogOut('token', 'accredian.com') }}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
                <Box
                  sx={{
                    float: "right",
                    mr: 5,
                    display: { xs: "none", lg: "block", sm: "block", md: "block" },
                  }}
                >
                  <Box sx={{ display: "flex", mt: 2 }}>
                    <Box>
                      <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                        alt="green iguana"
                        sx={{ width: 17, height: 17, borderRadius: "8px" }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          color: "#fea116",
                          fontWeight: "bold",
                          fontSize: "13px",
                          ml: 1,
                        }}
                      >
                        {coin}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    float: "right",
                    mr: 4,
                    display: { xs: "none", lg: "block", lg: "block", sm: "block", md: "block" },
                  }}
                >
                  {(() => {
                    if (category == "7" || category == "8") {
                      return (
                        <>
                          <Link to='/By-Program'>
                            <Button
                              variant="contained"
                              sx={{
                                background: "#1572A1",
                                boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                borderRadius: "50px",
                                textTransform: "none",
                                height: "40px",
                                mt: 0.9,
                                "&:hover": {
                                  background: "#1572A1",
                                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                  borderRadius: "50px",
                                  textTransform: "none",
                                  height: "40px",
                                },
                              }}
                            >
                              Purchase Full Course
                            </Button>
                          </Link>
                        </>
                      );
                    } else if (category == "2" || category == "4" || category == "1" || category == "3") {
                      return (
                        <Link to='/Program-Upgrade'>
                          <Button
                            variant="contained"
                            sx={{
                              background: "#1572A1",
                              boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                              borderRadius: "50px",
                              textTransform: "none",
                              height: "40px",
                              mt: 0.9,
                              ml: { sm: 5, md: 5 },
                              "&:hover": {
                                background: "#1572A1",
                                boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                borderRadius: "50px",
                                textTransform: "none",
                                height: "40px",
                              },
                            }}
                          >
                            Program Upgrade{" "}
                          </Button>
                        </Link>
                      );
                    }
                  })()}
                </Box>
                <Box
                  sx={{ float: "right", mr: { lg: 5, xs: 2 }, ml: { xs: 2, sm: 5, md: 35 } }}
                >
                  <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
                    <Badge
                      badgeContent={countnoti}
                      color="error"
                      sx={{ cursor: "pointer" }}
                      onClick={notifictionClick}
                    >
                      <NotificationsNoneIcon sx={{ color: "#a1a5b7" }} />
                    </Badge>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />

      <Popper id={id} open={opens} anchorEl={noanchorEl}>
        <Box sx={{ mt: 4 }}>
          <Box>
            <Box>
              <Typography
                sx={{
                  bgcolor: "background.paper",
                  borderTopLeftRadius: ".475rem",
                  borderTopRightRadius: ".475rem",
                  pl: 2,
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "40px",
                  py: 2,
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
              >
                Notifications
              </Typography>
            </Box>
            <List
              sx={{
                width: "368px",

                bgcolor: "background.paper",
                maxHeight: 260,
                mt: -3,
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px grey",
                  webkitBoxShadow: "inset 0 0 6px grey",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "grey",
                  outline: "1px solid grey",
                  borderRadius: "10px",
                },
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                mb: 2,
              }}
            >
              {notifiction &&
                notifiction.map((newnotiction) => (
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      borderBottom: "1px solid #e6e6e6",

                      "&:hover": {
                        background: "#eff2f5",
                      },
                    }}
                    component="a"
                    href={newnotiction.notification_url}
                    target="_blank"
                    onClick={() => checkdclick(newnotiction.id)}
                  >
                    <ListItemText
                      primary={
                        <>
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  fontWeight: "bold",
                                  lineHeight: "1.6",
                                  marginBottom: "0.8rem!important",
                                  width: "320px",
                                  color: "#000",
                                }}
                              >
                                {newnotiction.heading_text}
                              </Typography>
                            </Box>
                            {(() => {
                              if (
                                newnotiction.notification_url &&
                                newnotiction.checked != 1
                              ) {
                                return (
                                  <>
                                    <Box sx={{}}>
                                      <FiberManualRecordIcon
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "#009ef7",
                                        }}
                                      />
                                    </Box>
                                  </>
                                );
                              }
                            })()}
                          </Box>
                        </>
                      }
                      secondary={
                        <React.Fragment>
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  marginBottom: "0rem!important",
                                }}
                                color="#a1a5b7"
                              >
                                {newnotiction.notification_text}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "0.7rem",
                                  fontWeight: "bold",
                                  marginBottom: "0rem!important",
                                }}
                                color="#1572A1"
                              >
                                {newnotiction.duration} ago
                              </Typography>
                            </Box>
                            <Box></Box>
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
      </Popper>
      <ShareFeedback open={isModalOpen} setOpen={setIsModalOpen} />
      <Feedback open={isModalOpen} setOpen={setIsModalOpen} />
    </React.Fragment>
  );
}
LoginDashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default LoginDashboard;

import React, { useState, useEffect } from "react";
import {
  Box,
  Backdrop,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Dashborednav from "../Navbar/Dashborednav";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { ProgramCard } from "./programs/program-card";
import { APPLICATION_DATA, TABS } from "../../constants/constants";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import { fetchApplicationsService } from "../../services/users.services";
import ApplicationAccord from "./applications-accord";
import { groupApplicationsByStatus } from "../../helpers/common.helpers";
import ExploreProgramsCard from "./explore-programs-card";
import { GoPlus } from "react-icons/go";
import { fetchCategoriesService } from "../../services/programs.service";
import ExploreProgramsModal from "./explore-programs";

const Myaccount = () => {
  const [user, setUser] = useState("");
  const [enrooll, setEnrooll] = useState("");
  const [open, setOpen] = useState(false);
  const [programs, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [isExploreModalOpen, setIsExploreModalOpen] = useState(false);
  const [appData, setAppData] = useState([...APPLICATION_DATA]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get("tab") || "my-applications";
  const [isUserHasApplications, setIsUserHasApplications] = useState(false)
  const [sideTab, setSideTab] = useState(tabParam);
  const [isFaculty, setIsFaculty] = useState(false)


  useEffect(() => {
    const fetchToken = async () => {
      // console.log("called")
      const token = document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      if (token) {
        const options = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            authorization: token,
          },
        };
        await axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/verifyToken/token`,
            options
            // { withCredentials: true }
          )
          .then((result) => {
            if (result.data.status == 200) {
              const user_data = result.data.data;
              if(user_data.user_role === 7){
                setIsFaculty(true)
              }
              if (user_data.count > 1) {
                console.log(user_data.count, "enrol count");
                window.localStorage.setItem("user_id", user_data.user_id);
                window.localStorage.setItem("count", user_data.count);
                window.localStorage.setItem("username", user_data.firstname);
                window.localStorage.setItem(
                  "lastname",
                  user_data.userlastname
                );
                window.localStorage.setItem("email", user_data.email);
                window.localStorage.setItem("mobile", user_data.mobile);
                window.localStorage.setItem("dob", user_data.dob);
                window.localStorage.setItem("batch_id", user_data.batch_id);
                window.localStorage.setItem("term", user_data.term);
                // navigate(`/`);
                setLoading(false);
                LoaderOpen();
                const sendData = {
                  user_id: localStorage.getItem("user_id"),
                };
                // //console.log(auth)
                var user_id = localStorage.getItem("user_id");
                setUser(user_id);
                var enrooll = localStorage.getItem("count");
                setEnrooll(enrooll);
                axios
                  .post(
                    `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/fetchenrolments`,
                    JSON.stringify(sendData),
                    options,
                    { withCredentials: true }
                  )
                  .then((result) => {
                    setData(result.data);
                    LoaderClose();

                  });
                if (user_data.program_id == 74) {
                  axios.post(
                    `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/fetchIITKUserDetails`,
                    {
                      user_id: user_data.user_id
                    }
                  ).then((res) => {
                    // console.log(res, "seee")
                    if (res.data.status == 200) {
                      const { address1, city, country, current_organization, dob, gender, mobile, pincode, linkedin, qualification, university, industry, designation, work_ex, technology } = res.data.data[0]
                      if (!(address1 && city && country && current_organization && dob && gender && mobile && pincode && linkedin && qualification && university && industry && designation && work_ex && technology)) {
                        navigate(`/studentDetails`);
                      }


                    }
                  })
                }
              } else if (user_data.count == "1" || user_data.count == "0") {
                window.localStorage.setItem("user_id", user_data.user_id);
                window.localStorage.setItem("enrol_id", user_data.enrol_id);
                window.localStorage.setItem("category", user_data.category);
                window.localStorage.setItem("count", user_data.count);
                window.localStorage.setItem("username", user_data.firstname);
                window.localStorage.setItem(
                  "lastname",
                  user_data.userlastname
                );
                window.localStorage.setItem("email", user_data.email);
                window.localStorage.setItem("mobile", user_data.mobile);
                window.localStorage.setItem("user_role", user_data.user_role);
                window.localStorage.setItem(
                  "program_id",
                  user_data.program_id
                );
                window.localStorage.setItem("dob", user_data.dob);
                window.localStorage.setItem("batch_id", user_data.batch_id);
                window.localStorage.setItem("term", user_data.term);
                const sendData = {
                  user_id: localStorage.getItem("user_id"),
                };
                axios
                  .post(
                    `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/fetchenrolments`,
                    JSON.stringify(sendData),
                    options,
                    { withCredentials: true }
                  )
                  .then((result) => {
                    // console.log(result);
                    window.localStorage.setItem(
                      "program_name",
                      result.data[0].program_name
                    );
                    setData(result.data);
                    LoaderClose();

                  });


                setLoading(false);
                if (user_data.program_id == 74) {
                  axios.post(
                    `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/fetchIITKUserDetails`,
                    {
                      user_id: user_data.user_id
                    }
                  ).then((res) => {
                    console.log(res, "seee")
                    if (res.data.status == 200) {
                      const { address1, city, country, current_organization, dob, gender, mobile, pincode, linkedin, qualification, university, industry, designation, work_ex, technology } = res.data.data[0]
                      if (address1 && city && country && current_organization && dob && gender && mobile && pincode && linkedin && qualification && university && industry && designation && work_ex && technology) {
                        navigate(`/Dashboard`);
                      }
                      else {
                        navigate(`/studentDetails`);
                      }
                    }
                  })
                }
              }
            } else {
              // console.log(result.data.messaage)
              // alert(result.data.messaage)
              localStorage.clear();
              Swal.fire({
                title: "Timeout",
                text: "Your Session has been expired please relogin",
                icon: "info",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "https://accredian.com";
                }
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Timeout",
              text: "Your Session has been expired please relogin",
              icon: "info",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "https://accredian.com";
              }
            });
            localStorage.clear();
          });
      } else {
        // window.location.href = 'http://localhost:3000https://frontend-project2.accredian.com/login';
        // console.log("no token")
        localStorage.clear();
        Swal.fire({
          title: "Timeout",
          text: "Your Session has been expired please relogin",
          icon: "info",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "https://accredian.com";
          }
        });
      }
    };
    fetchToken();
  }, []);

  const handleClickOpen = (
    item
  ) => {
    const { enrol_id, category, program_id, term, program_name, program_code } = item
    //console.log(enrol_id, category);
    window.localStorage.setItem("enrol_id", enrol_id);
    window.localStorage.setItem("category", category);
    window.localStorage.setItem("program_id", program_id);
    window.localStorage.setItem("term", term);
    window.localStorage.setItem("program_name", program_name);
    window.localStorage.setItem("program_code", program_code);
    navigate(`/Dashboard`);
  };
  const LoaderOpen = () => {
    setOpen(true);
  };
  const LoaderClose = () => {
    setOpen(false);
  };

  const { data, isFetching: isFetchingApplication } = useQuery({
    queryFn: ({ user_id }) => fetchApplicationsService({ user_id }),
    queryKey: ["applications"],
    enabled: localStorage.getItem("user_id") !== undefined
  })

  const { data: categoriesData, isFetching: isFetchingCategories } = useQuery({
    queryFn: () => fetchCategoriesService(),
    queryKey: ["program"],
    enabled: localStorage.getItem("user_id") !== undefined
  })

  useEffect(() => {
    if (data && categoriesData) {
      const grouped = groupApplicationsByStatus(data.applications, categoriesData);

      const updatedAppData = [...APPLICATION_DATA];
      updatedAppData[0].applications = grouped?.ongoing;
      updatedAppData[1].applications = grouped?.completed;

      if (grouped?.ongoing?.length || grouped?.completed?.length) {
        setIsUserHasApplications(true)
      }

      setAppData(updatedAppData);
    }
  }, [data, categoriesData]);


  useEffect(() => {
    setSideTab(tabParam);
  }, [tabParam]);

  useEffect(() => {
    if(isFaculty){
      navigate('/dashboard')
    }
  }, [isFaculty, navigate]);

  const handleTabClick = (tab) => {
    setExpanded(false)
    setSideTab(tab);
    navigate(`?tab=${tab.toLowerCase().replace(/\s+/g, "-")}`);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "50% auto",
          }}
        >
          {" "}
          <CircularProgress color="inherit" />
          Loading....
        </Box>
      ) : (
        <Box>
          <Dashborednav />
          <div className="w-full xl:px-12 px-0 lg:px-4 py-0 lg:py-16 min-h-[80vh] flex justify-center font-circular">
            <div className="w-full max-w-[85rem] flex gap-2 lg:flex-row flex-col">
              <div className="w-full flex lg:hidden px-4 py-2">
                <button onClick={() => handleTabClick(sideTab.toLocaleLowerCase() === "my-applications" ? "my-courses" : "my-applications")} className="px-4 py-2 bg-universal text-white font-semibold w-full flex items-center justify-between rounded-md">
                  <h1>{sideTab.toLocaleLowerCase() === "my-applications" ? "View My Courses" : "View My Applications"}</h1>
                  <div className='w-5 h-5'>
                    <svg className='w-full h-full' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 6L15 12L9 18" stroke="white" strokeWidth="1.3" />
                    </svg>
                  </div>
                </button>
              </div>
              <div className='flex-[0.25] sticky top-[15%] hidden lg:flex flex-col gap-3 max-h-[70vh] overflow-y-auto custom-scrollbar px-2'>
                {TABS.map((val, index) => (
                  <div className={`min-w-[230px] max-w-[320px] w-full flex-shrink-0 rounded-md drop-shadow-md flex items-center justify-between gap-3 px-4 py-2.5 ${sideTab.toLocaleLowerCase() === val.id.toLocaleLowerCase() ? "bg-universal text-white" : "bg-white text-black"} cursor-pointer`} onClick={() => handleTabClick(val.category)} key={index}>
                    <div className='flex items-center gap-2'>
                      <div className='w-4 h-4'>
                        <val.icon className={`w-full h-full ${sideTab.toLocaleLowerCase() === val.id.toLocaleLowerCase() ? "text-white" : "text-slate-900"}`}></val.icon>
                      </div>
                      <h1 className='font-medium text-nowrap text-sm capitalize'>{val.category}</h1>
                    </div>
                    <div className='w-5 h-5'>
                      <svg className='w-full h-full' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 6L15 12L9 18" stroke={sideTab.toLocaleLowerCase() === val.id.toLocaleLowerCase() ? "white" : "#222222"} strokeWidth="1.3" />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex-1 lg:flex-[0.75] lg:px-0 px-4">
                {sideTab.toLocaleLowerCase() === "my-applications" ? (
                  <>
                    <div className="w-full flex justify-between items-center">
                      <h1 className="text-lg md:text-2xl font-semibold">My Applications</h1>
                      <button onClick={() => setIsExploreModalOpen(true)} className="flex items-center gap-1 px-4 py-2 text-universal hover:bg-blue-200 transition-all duration-200 rounded-lg">
                        <div className="w-4 md:w-5 h-4 md:h-5 flex-shrink-0">
                          <GoPlus className="w-full h-full" />
                        </div>
                        <h1 className="font-medium text-sm md:text-base">Start new Application</h1>
                      </button>
                    </div>
                    <div className="mt-4">
                      {(isFetchingApplication || isFetchingCategories) ? (
                        <div className="w-full h-[50vh] flex items-center justify-center">
                          <div className="flex flex-col items-center gap-5">
                            <CircularProgress color="inherit" />
                            <h1 className="text-lg font-medium">Please wait while we are fetching your applications...</h1>
                          </div>
                        </div>
                      ) : isUserHasApplications ? appData.map((tab, index) => (
                        <ApplicationAccord isModalOpen={isExploreModalOpen} setIsModalOpen={setIsExploreModalOpen} handleClickOpen={handleClickOpen} expanded={expanded} setExpanded={setExpanded} tab={tab} key={tab.id} />
                      )) : (
                        <div className="w-full h-[50vh] flex items-center justify-center">
                          <div className="flex flex-col items-center gap-5">
                            <h1 className="text-lg font-medium">Please click on the Start new Application button to start new one.</h1>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : programs.length ? (
                  <>
                    <h1 className="text-2xl font-semibold">My Courses</h1>
                    <div className="w-full flex flex-wrap gap-8 mt-10">
                      {programs.map((item, index) => (
                        <ProgramCard key={index} item={item} handleClickOpen={handleClickOpen} />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="w-full flex flex-col gap-6">
                    <div className="w-full p-8 bg-white border drop-shadow-sm rounded-xl flex gap-2">
                      <div className="w-4 md:w-7 h-4 md:h-7 flex-shrink-0 mt-0.5">
                        <IoMdInformationCircleOutline className="w-full h-full text-universal" />
                      </div>
                      <h1 className="text-base md:text-lg font-medium">It appears that you are not currently enrolled in any program. Please either complete your application if it is in progress or select a course from the options below to proceed.</h1>
                    </div>
                    <ExploreProgramsCard perks />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ExploreProgramsModal open={isExploreModalOpen} setOpen={setIsExploreModalOpen} />
    </>
  );
};
export default Myaccount;

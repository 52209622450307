import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Container,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { admissionspolic } from "../../../data/admissionspolic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "300px",
  },
}));
const BsDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "800px",
  },
}));
const StudentPolicy = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategorys] = useState("");
  // Use for Navigate on Previous
  let navigate = useNavigate();
  const [data, setData] = useState({ policy_check: "" });
  const { policy_check } = data;
  //console.log(data,"see")
  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/policycheck`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      if(res.data.policy_check == "0"){
        setData({ policy_check: 0 });
      }else{
        setData({ policy_check: 1 } );
      }
    });
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategorys(category);
    fetchData();
    getUserIp();
  }, []);
  const [user_ip, setIp] = useState("");
  const getUserIp = async () => {
    const ip = await axios.get("https://geolocation-db.com/json/");
    //console.log(ip.data);
    setIp(ip.data.IPv4);
  };
  const submitForm = async (e) => {
    var user_id = localStorage.getItem("user_id");
    e.preventDefault();
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const sandData = {
      user_ip,
      user_id,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/updatepolicy`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == "200") {
          toast.success("Pollicy Accepted Successfully", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOpen(false);
          fetchData();
        } else {
          toast.error("🦄 Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  // if (user == null) {
  //  window.location.href="https://accredian.com"
  // }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper
        sx={{
          mx:{lg:50,md:50,sm:30},
          borderRadius: "8px",
          boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
        }}
      >
        <Box>
          <Grid container>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3, my: 2, mx: 5,pl:{lg:0,sm:4,md:0,xs:10} }}>
              <Button
                variant="contained"
                sx={{
                  background: "#009ef7",

                  borderRadius: "8px",
                  textTransform: "none",

                  "&:hover": {
                    background: "#009ef7",

                    borderRadius: "8px",
                    textTransform: "none",
                  },
                }}
                onClick={handleClickOpen}
              >
                Student Policy
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box>
        {(() => {
          if (policy_check == 1) {
            return (
              <>
                <BootstrapDialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{ fontSize: "1rem", fontWeight: "bold" }}
                  >
                    You have already accepted the TnC{" "}
                    {/* <Link
                      to={"/Policy/admissions-policy"}
                      style={{ textDecoration: "none" }}
                    > */}
                    <a href={`https://accredian.com/policies/accredian-policy`} style={{ textDecoration: "none" }} target="_blank">
                      Click here{" "}
                    </a>
                    to review.
                  </DialogTitle>

                  <DialogActions>
                    <Button
                      variant="contained"
                      sx={{
                        background: "#e4e6ef",
                        color: "#000",
                        borderRadius: "8px",
                        textTransform: "none",

                        "&:hover": {
                          background: "#e4e6ef",
                          color: "#000",
                          borderRadius: "8px",
                          textTransform: "none",
                        },
                      }}
                      onClick={handleClose}
                    >
                      Okay
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </>
            );
          } else {
            return (
              <>
                <BsDialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title" sx={{ p: 0, m: 0 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        background: "#181C32",
                        p: 3,
                        fontSize: "1.4rem",
                        fontWeight: "bold",
                      }}
                    >
                      Student Policy
                    </Typography>
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: "0.2em",
                      },
                      "&::-webkit-scrollbar-track": {
                        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(0,0,0,0)",
                        outline: "1px solid #fff",
                      },
                    }}
                  >
                    <DialogContentText id="alert-dialog-description">
                      {admissionspolic.map((pd, index) => (
                        <Container fixd sx={{ ml: 2 }}>
                          <Typography
                            sx={{
                              color: "#00",
                              textDecoration: "none",
                              fontWeight: "bold",
                              fontSize: "14px",

                              marginBottom: "0.8rem!important",
                              ml: 3,
                              textAlign: "justify",
                            }}
                            key={pd.index}
                          >
                            {pd.policyhadding}
                          </Typography>
                          {pd.policydescription &&
                            pd.policydescription.map((pdd) => (
                              <Typography
                                sx={{
                                  color: "#00",
                                  textDecoration: "none",

                                  fontSize: "14px",

                                  marginBottom: "1rem!important",
                                  mx: 3,
                                  textAlign: "justify",
                                }}
                              >
                                {pdd}
                              </Typography>
                            ))}
                        </Container>
                      ))}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      sx={{
                        background: "#e4e6ef",
                        color: "#000",
                        borderRadius: "8px",
                        textTransform: "none",

                        "&:hover": {
                          background: "#e4e6ef",
                          color: "#000",
                          borderRadius: "8px",
                          textTransform: "none",
                        },
                      }}
                      onClick={submitForm}
                    >
                      Accept policy
                    </Button>
                  </DialogActions>
                </BsDialog>
              </>
            );
          }
        })()}
      </Box>
    </>
  );
};
export default StudentPolicy;

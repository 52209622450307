
import React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PauseProgram from "../Components/Dashboard/Manage-My-Program/PauseProgram";
import Swal from 'sweetalert2'
const AuthPauseprogram = () => {
   const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');

    if(token){
      return true
    } else {
      localStorage.clear()
      return false
    }
  }
  const auth = useAuth();
  return auth ? <PauseProgram /> : <>
  {
     Swal.fire({
      title: "Timeout",
      text: "Your Session has been expired please relogin",
      icon: "info",
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href="https://accredian.com"
      }
    })
  }
  </>
}
export default AuthPauseprogram;

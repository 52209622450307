import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const AttendanceModal = ({isModalOpen, setIsModalOpen, table}) => {

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="w-full max-w-[90vw] md:max-w-[60vw] lg:max-w-[40vw]">
        <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <h1 className="">Attendance Details</h1>
          <ClearIcon onClick={handleClose} className="cursor-pointer" />
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead sx={{ position: "sticky", top: 0 }}>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>


              <TableBody>
                {table && table.map((row) => (
                  <TableRow hover>
                    <TableCell width={300}>{new Date(row?.attendance_date).toLocaleDateString("en-IN", { day: "2-digit", month: "short", year: "numeric" })}</TableCell>
                    <TableCell width={300} sx={{ textTransform: "capitalize" }}>{row?.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>

            </Table>
          </TableContainer>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default AttendanceModal;
import React, { useEffect, useState } from "react";
import { Typography, Button, Box, Paper, Grid,  Backdrop,CardMedia } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../Navbar/MobileSidebar";
import axios from "axios";
import Sidbar from "./DesktopSidebar";
import Moment from 'react-moment';
import moment from 'moment';
import logo from '../../images/accredian-logo.png'
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import AuthSidebar from "../../Auth/AuthSidebar";
const CertificationExam = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  const [program, setProgram] = useState("");
  const [programcode, setProgramcode] = useState("");
  const [data, setData]= useState("");
  const [issudedate, setIssudedate]= useState("");
  const [loopen, setLoOpen] = useState(false);
  const [showExam,setShowexam]=useState("")
  // Use for Navigate on Previous
  let navigate = useNavigate();

  const fetchcertificationExam = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certification_exam/certificationExam`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
        program_id: localStorage.getItem("program_id"),
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      setShowexam(res.data.certificateRow.eschedule);
      localStorage.setItem("issude_date", res.data.certificateRow.issued_on);



      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  
  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certification_exam/exam-registeration`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
        program_id : localStorage.getItem("program_id"),
        email : localStorage.getItem("email"),
        firstname : localStorage.getItem("username"),
        lastname : localStorage.getItem("lastname"),
        dob : localStorage.getItem("dob"),
        mobile: localStorage.getItem("mobile"),
      },
    }).then((res) => {
      //console.log(res.data,"exmadata");
      setData(res.data);
    });
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var program_id = localStorage.getItem("program_id");
    var program_code = localStorage.getItem("program_code");
    var issude_date = localStorage.getItem("issude_date");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
    setProgram(program_id);
    setProgramcode(program_code);
    setIssudedate(issude_date);
    fetchData();
    fetchcertificationExam()
  }, []);
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}
  const checkoutHandler = async (amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
  }
    const options = {
      key:process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount * 100,
      currency: "INR",
      name: "Accredian",
      description:  "certification exam fee for " + localStorage.getItem("program_code"),
      image:
       {logo},
      // order_id: order.data.response.id,
      handler: function (response) {
        LoaderOpen();
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certification_exam/certificateFee`,
          data: {
            enrol_id:localStorage.getItem("enrol_id"),
            user_id:localStorage.getItem("user_id"),
            email:localStorage.getItem("email"),
            mobile:localStorage.getItem("mobile"),
            firstname:localStorage.getItem("username"),
            lastname:localStorage.getItem("lastname"),
            amount:amount * 100,
            payment_id:response.razorpay_payment_id,
            program_id:localStorage.getItem("program_id"),
            examstatus:"paid",
            description: "certification exam fee for " + localStorage.getItem("program_code"),
          },
        }).then((res)=>{
          // //console.log(res,"razorpay")
          Swal.fire({
            title: "Thank you",
            text: "Your payment has been received successfully,Click on the below Access Exam button to access the certification exam",
            icon: "success",
            confirmButtonText: "Access Exam",
          }).then((conf) => {
              if (conf.isConfirmed) {
                window.location.reload();
              }
            });
      
          LoaderClose();
        })
      
      },
      prefill: {
        name:
          localStorage.getItem("firstname") + localStorage.getItem("lastname"),
        email: localStorage.getItem("email"),
        contact: localStorage.getItem("mobile"),
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();

    // //console.log("chala kya");
  };
  // if (user == null) {
  //  window.location.href="https://accredian.com"
  // }
  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: 8 }}>
          <Grid container>
          <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
              {/* <AuthSidebar/> */}
            </Grid>
            {showExam==1?(
              <Grid item lg={9}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "1rem!important",
                  }}
                >
                  Certification Exam
                </Typography>
              </Box>
              <Box sx={{ mb: 4 }}>
                <Paper
                  sx={{
                    ml: 3,
                    mr: 10,
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 2,
                  }}
                >
                  <Box sx={{ borderBottom: "0.5px solid #eff2f5" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Instructions
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    {(() => {
                      if (category < 3) {
                        return (
                          <>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              1.Read the instructions carefully.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              2.You will have a timeline of <b>180 days</b> from 
                               <Moment format='D MMM YYYY'>{issudedate}</Moment>, to attempt the test.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              3.This is a <b>4 hours</b> timed test under a
                              marginal proctored environment<b>(Mettl)</b>. You
                              need to complete the test in{" "}
                              <b>ONE sitting of 4 hours.</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              4.This test is for <b>150 marks</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              5.You need to score at least{" "}
                              <b>90 Marks to pass the test.</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              6.If you score above the passing grade you are
                              entitled to the {programcode} certificate.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              7.The first attempt is <b>FREE.</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              8.In case you <b>miss giving</b> the exam in the
                              provided timeline or{" "}
                              <b>do not get above passing grade,</b> then you
                              would need to re-attempt at a later date, which
                              will be{" "}
                              <b>chargeable. (INR 250/every next attempt)</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              9.Kindly <b>call the phone number</b> provided at
                              the bottom-center of the Mettl interface in case
                              of <b>any technical issues during the test.</b>
                            </Typography>
                          </>
                        );
                      } else if (category >= 3 && category < 5) {
                        return (
                          <>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              1.Read the instructions carefully.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              2.You will have a timeline of <b>180 days</b> from  
                              <Moment format='D MMM YYYY'>{issudedate}</Moment>, to attempt the test.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              3.This is a <b>1.5-hour</b> timed test under a
                              marginal <b> proctored environment </b>(Mettl)
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              4.The exam will consist of objective questions
                              (MCQ).
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              5.You need to complete the test in{" "}
                              <b>ONE sitting</b> of <b>1.5 hours</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              6.This test is for<b>100 marks</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              7.You need to score at least <b>60 Marks</b> to
                              pass the test.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              8.If you score above the passing grade you are
                              entitled to the <b>Merit Certificate</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              9.In case you <b>FREE</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              10.The first attempt is <b>miss</b> giving the
                              exam in the <b>provided timeline</b> or do not get
                              above passing grade, then you would need to
                              re-attempt later, which will be chargeable.{" "}
                              <b>(INR 500/every next attempt)</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              11.Kindly <b>call</b> the <b>phone number</b>{" "}
                              provided at the<b>bottom-center</b> of the{" "}
                              <b>Mettl interface</b>in case of any technical
                              issues during the test.
                            </Typography>
                          </>
                        );
                      }
                    })()}
                  </Box>
                </Paper>
              </Box>

              <Box sx={{ mb: 4 }}>
                <Paper
                  sx={{
                    ml: 3,
                    mr: 10,
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 2,
                  }}
                >
                  <Box sx={{ borderBottom: "0.5px solid #eff2f5" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Exam Format
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    {(() => {
                      if (programcode == "CDF") {
                        return (
                          <>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.9rem !important",
                                fontWeight: "bold",
                              }}
                            >
                              Structure of the Test:{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              1.All the questions are Multiple Choice Questions
                              and each question has only{" "}
                              <b>one correct answer.</b>{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              2.There are <b>two sections </b> in this test.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                fontWeight: "bold",
                              }}
                            >
                              3.Section 1:- 30 Questions{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                ml: 2,
                              }}
                            >
                              <FiberManualRecordOutlinedIcon
                                sx={{ fontSize: "0.7rem", mr: 1 }}
                              />
                              Each question carries <b>1 marks. </b>{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                ml: 2,
                              }}
                            >
                              <FiberManualRecordOutlinedIcon
                                sx={{ fontSize: "0.7rem", mr: 1 }}
                              />
                            Quiz questions on Python, EDA, and Machine Learning Foundation{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                fontWeight: "bold",
                              }}
                            >
                              4.Section 2:- 15 Questions{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                ml: 2,
                              }}
                            >
                              <FiberManualRecordOutlinedIcon
                                sx={{ fontSize: "0.7rem", mr: 1 }}
                              />
                              Each question carries <b>6 mark.</b>{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                ml: 2,
                              }}
                            >
                              <FiberManualRecordOutlinedIcon
                                sx={{ fontSize: "0.7rem", mr: 1 }}
                              />
                              Quiz questions on Python, EDA, Machine Learning
                              Foundation and Machine Learning Intermediate.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                ml: 2,
                              }}
                            >
                              <FiberManualRecordOutlinedIcon
                                sx={{ fontSize: "0.7rem", mr: 1 }}
                              />
                              A link to a dataset is provided.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                ml: 2,
                              }}
                            >
                              <FiberManualRecordOutlinedIcon
                                sx={{ fontSize: "0.7rem", mr: 1 }}
                              />
                              The answer to the question is based on the result
                              obtained after performing certain operations on
                              the dataset.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              5.You can attempt the Sections or Questions{" "}
                              <b>in any order.</b>{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                fontWeight: "bold",
                              }}
                            >
                              Things required while attempting this test:{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              1.Python ( Jupyter notebook / Spyder ){" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              2.Rough paper and pen.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                fontWeight: "bold",
                              }}
                            >
                              Things to remember:{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              1.<b>Do not press “F5”</b> during the test at any
                              time as doing so will cause your test to finish
                              abruptly.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              2.If your test has any section which has a time
                              limit of its own, you will be able to attempt that
                              section only once and cannot return to it. You
                              will be warned when you want to exit each section.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              3.
                              <b>
                                Important: In case your test window suddenly
                                closes or your system shuts down due to a power
                                failure,
                              </b>{" "}
                              you can resume your test from where you had left{" "}
                              <b>within a few minutes (within 20 minutes).</b>{" "}
                              The answers for the questions previously attempted
                              will be saved. Follow the same steps that you took
                              in the beginning to start the test and use the
                              same registration details.{" "}
                            </Typography>
                          </>
                        );
                      } else if (category >= 3 && category < 5) {
                        return (
                          <>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              1.Read the instructions carefully.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              2.You will have a timeline of <b>180 days</b> from 
                              <Moment format='D MMM YYYY'>{issudedate}</Moment>, to attempt the test.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              3.This is a <b>1.5-hour</b> timed test under a
                              marginal <b> proctored environment </b>(Mettl)
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              4.The exam will consist of objective questions
                              (MCQ).
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              5.You need to complete the test in{" "}
                              <b>ONE sitting</b> of <b>1.5 hours</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              6.This test is for<b>100 marks</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              7.You need to score at least <b>60 Marks</b> to
                              pass the test.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              8.If you score above the passing grade you are
                              entitled to the <b>Merit Certificate</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              9.In case you <b>FREE</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              10.The first attempt is <b>miss</b> giving the
                              exam in the <b>provided timeline</b> or do not get
                              above passing grade, then you would need to
                              re-attempt later, which will be chargeable.{" "}
                              <b>(INR 500/every next attempt)</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              11.Kindly <b>call</b> the <b>phone number</b>{" "}
                              provided at the<b>bottom-center</b> of the{" "}
                              <b>Mettl interface</b>in case of any technical
                              issues during the test.
                            </Typography>
                          </>
                        );
                      }
                    })()}
                  </Box>
                </Paper>
              </Box>
              <Box>
                <Paper
                  sx={{
                    ml: 3,
                    mr: 10,
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 5.5,
                 
                  }}
                >
                  <Box sx={{ borderBottom: "0.5px solid #eff2f5" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Accredian Honor Code
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    {(() => {
                      if (category < 3) {
                        return (
                          <>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.9rem !important",
                              }}
                            >
                              Academic integrity is important to Accredian. Your
                              commitment to academic integrity shows respect for
                              your own work and the work of your peers.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                fontWeight: "bold",
                              }}
                            >
                              Honor Code
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              You need to follow Accredian’s Honor Code to uphold
                              Accredian's standard of academic integrity:{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              <FiberManualRecordOutlinedIcon
                                sx={{ fontSize: "0.7rem", mr: 1 }}
                              />
                              Your answers to quizzes and exams must be your
                              own.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              <FiberManualRecordOutlinedIcon
                                sx={{ fontSize: "0.7rem", mr: 1 }}
                              />
                              Please do not share your solutions to quizzes or
                              exams with anyone else unless explicitly permitted
                              by the instructor. This includes anything written
                              by you, as well as any official solutions provided
                              by the course staff.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              <FiberManualRecordOutlinedIcon
                                sx={{ fontSize: "0.7rem", mr: 1 }}
                              />
                              You may not engage in any other activities that
                              will dishonestly improve your results or
                              dishonestly improve or damage the results of
                              others.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                                fontWeight: "bold",
                              }}
                            >
                              Plagiarism{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              Plagiarism is when you copy words, ideas, or any
                              other materials from another source without giving
                              credit. Plagiarism is unacceptable in an academic
                              environment and is a serious violation of Accredian's
                              Honor Code.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              If some or all of your assignment is identified as
                              plagiarism, you may lose all the credit you
                              received for the assignment. And if you earned a
                              Course Certificate for that course, it may be
                              taken away.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              We understand our learners come from many
                              different academic backgrounds, and many are
                              unfamiliar with our academic standards for
                              plagiarism. This violation of our policies may
                              have been a one time mistake, but if you are found
                              repeatedly plagiarizing, you may be removed from
                              the course or even the Accredian community.{" "}
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              Report Honor Code violations{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              You can report Honor Code violations by contacting
                              Accredian support.{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              {" "}
                              <b>Note:</b> By clicking <b>Proceed</b>, you AGREE
                              to abide by the Accredian Honor code.{" "}
                            </Typography>
                          </>
                        );
                      } else if (category >= 3 && category < 5) {
                        return (
                          <>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              1.Read the instructions carefully.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              2.You will have a timeline of <b>180 days</b> from 
                              <Moment format='D MMM YYYY'>{issudedate}</Moment>, to attempt the test.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              3.This is a <b>1.5-hour</b> timed test under a
                              marginal <b> proctored environment </b>(Mettl)
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              4.The exam will consist of objective questions
                              (MCQ).
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              5.You need to complete the test in{" "}
                              <b>ONE sitting</b> of <b>1.5 hours</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              6.This test is for<b>100 marks</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              7.You need to score at least <b>60 Marks</b> to
                              pass the test.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              8.If you score above the passing grade you are
                              entitled to the <b>Merit Certificate</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              9.In case you <b>FREE</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              10.The first attempt is <b>miss</b> giving the
                              exam in the <b>provided timeline</b> or do not get
                              above passing grade, then you would need to
                              re-attempt later, which will be chargeable.{" "}
                              <b>(INR 500/every next attempt)</b>
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.84rem",
                                marginBottom: "0.8rem !important",
                              }}
                            >
                              11.Kindly <b>call</b> the <b>phone number</b>{" "}
                              provided at the<b>bottom-center</b> of the{" "}
                              <b>Mettl interface</b>in case of any technical
                              issues during the test.
                            </Typography>
                          </>
                        );
                      }
                    })()}
                    
                 
                  </Box>
                  {(() => {
                     if (data.exam_flag == "paid" && data.exam_fee == null ) {
                      return (
                        <Button
                        variant="contained"
                        sx={{
                          background: "#009ef7",
                       float:"right",
                          borderRadius: "8px",
                          textTransform: "none",
                          mb:2,
        
                          "&:hover": {
                            background: "#009ef7",
        
                            borderRadius: "8px",
                            textTransform: "none",
                          },
                        }}
                        onClick={() => {
                          checkoutHandler(250);
                        }}
                      >
                        Pay
                      </Button>
                      );
                    } 
                   else if (data.exam_flag == "paid" && data.exam_fee == "paid" ) {
                      return (
                        <Button
                        variant="contained"
                        sx={{
                          background: "#009ef7",
                       float:"right",
                          borderRadius: "8px",
                          textTransform: "none",
                          mb:2,
        
                          "&:hover": {
                            background: "#009ef7",
        
                            borderRadius: "8px",
                            textTransform: "none",
                          },
                        }}
                      href={data.examUrl}
                      target="_blank"
                      >
                        Proceed
                      </Button>
                      );
                    } else {
                      return (
                        <Button
                        variant="contained"
                        sx={{
                          background: "#009ef7",
                          float:"right",
                          borderRadius: "8px",
                          textTransform: "none",
                          mb:2,
                          "&:hover": {
                            background: "#009ef7",
        
                            borderRadius: "8px",
                            textTransform: "none",
                          },
                        }}
                        href={data.examUrl}
                        target="_blank"
                      >
                       Proceed
                      </Button>
                      );
                    }
                  })()}
                </Paper>
              </Box>
            </Grid>
            ):(
              // <Grid item lg={9}>
              // <Box>
              //   <Typography
              //     sx={{
              //       fontWeight: "bold",
              //       fontSize: "1rem",
              //       marginBottom: "1rem!important",
              //     }}
              //   >
              //     Certification Exam
              //   </Typography>
              // </Box>
              // <Box sx={{ mb: 4 }}>
              //   <Paper
              //     sx={{
              //       ml: 3,
              //       mr: 10,
              //       borderRadius: "8px",
              //       boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
              //       p: 2,
              //     }}
              //   >
              //     <Box>
              //       <Typography>
              //         You are not eligible for this exam
              //       </Typography>
              //     </Box>
              //   </Paper>
              //   </Box>
              //   </Grid>
              <Box sx={{width:{lg:"50%",xs:"100%"},ml:{lg:"8%",xs:"4%"}}}>
                <Box>
                 <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "1rem!important",
                  }}
                >
                  Certification Exam
                </Typography>
              </Box>
                 <Paper sx={{p:6,borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"center",width:{lg:"auto",md:"95%",sm:"95%",xs:"95%"}}} elevation={3}>
              <Box>
                  <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <CardMedia
                    component="img"
                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/Growth%20curve-amico%20(1).png"
                    alt="Goole"
                    sx={{
                     width:"250px",
                     height:"200px",
                     mb:2,
                      // height:"100%",382px
                      display: { xs: "block", lg: "block",sm:"block",md:"block" },
                    }}
                  />
                  </Box>
                  <Typography sx={{fontWeight:"500",fontSize:"25px",textAlign:"center",mt:2}}>
              Unfortunately!!
          </Typography>
              <Typography sx={{fontWeight:"500",fontSize:"22px",textAlign:"center"}}>
              You are not eligible for this exam
          </Typography>
         
         
              </Box>
               
              
         
          </Paper>
              </Box>
             
            )}
            
          </Grid>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default CertificationExam;

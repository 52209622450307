import React, { useEffect, useState } from "react";
import "./dashboard.css";
import StudentDashboard from "./StudentDashboard";
import TryalDashboard from "./TryalDashboard";
import Facultydashboard from "./Facultydashboard";
import DashboardWithoutEnrollments from "./dashboard-without-enrollments/dashboard-without-enrollments";
// import HowTo from "./Howto";
const Dashboard = () => {

  const [user, setUser] = useState("");
  const [category, setCategory] = useState("");
  const [enrolid, setEnrolide] = useState("");
  const [userrole, setUserrole] = useState("");

  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var user_role = localStorage.getItem("user_role");

    setUser(user_id);
    setCategory(category);
    setUserrole(user_role);
    setEnrolide(enrol_id);
  }, []);

  // if (user == null) {

  //   window.location.href="https://accredian.com"
  // }

  return (
    <>
      {(() => {
        if (userrole === '7') {
          return (
            <>
              <Facultydashboard />
            </>
          );
        }
        else if (enrolid === 'undefined') {
          return (
            <>
              <DashboardWithoutEnrollments />
            </>
          );
        }
        else if (category === "7" || category === "8") {
          return (
            <>
              <TryalDashboard />
            </>
          );
        }
        else {
          return (
            <>
              <StudentDashboard />
            </>
          );
        }
      })()}
    </>
  );
};
export default Dashboard;

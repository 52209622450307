// import Accordion from '@mui/joy/Accordion';
// import AccordionDetails from '@mui/joy/AccordionDetails';
// import AccordionSummary from '@mui/joy/AccordionSummary';
import { useEffect, useState } from 'react';
import { formatDate, getTotalSteps, paymentPortalRedirectionURL } from '../../helpers/common.helpers';
import { LuChevronsDown } from "react-icons/lu";
import ExploreProgramsModal from './explore-programs';


const ApplicationAccord = ({ tab, setExpanded, expanded, handleClickOpen, isModalOpen, setIsModalOpen }) => {

  // const handleAccordionChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  const [isShowAll, setIsShowAll] = useState(false)
  const [applications, setApplications] = useState([])

  useEffect(() => {
    if (isShowAll) {
      setApplications(tab.applications)
    } else {
      setApplications(tab.applications.slice(0, 2))
    }
  }, [isShowAll, tab.applications])

  const handleShowAll = () => {
    setIsShowAll(!isShowAll)
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth"
    // })
  }

  return (
    <div className='w-full my-2 py-4'>
      <div className='w-full'>
        <div className='w-full flex items-center justify-between'>
          <h1 className='text-base md:text-xl font-semibold'>{tab.title}</h1>
          <h4>{tab.applications.length}</h4>
        </div>
        <div className='w-full border-b-2 mt-2 border-neutral-200' />
      </div>
      <div className="w-full flex flex-col gap-4 mt-4">
        {applications.map((application, index) => {
          const totalSteps = getTotalSteps(application.university);
          const percentage = Math.floor((application.step / totalSteps) * 100);
          const isApplicationCompleted = percentage === 100 && application.step_status === "complete"
          const isRevoked = application?.status === "revoked"
          return (
            <div key={index} className="w-full bg-white drop-shadow-sm border flex md:items-center md:flex-row flex-col md:gap-8 gap-4 md:p-8 p-4 rounded-xl">
              <div className="flex-1">
                <div className="w-full flex items-center justify-between">
                  <h1 className="text-lg md:text-2xl font-semibold capitalize"> {application.university !== "unknown" && <span className='uppercase'>{application.university} -</span>}  {application.vertical}</h1>
                  {isApplicationCompleted && application.batch_date && (
                    <h4 className=" text-sm"><span className="font-semibold">Batch:</span> {formatDate(application.batch_date)}</h4>
                  )}
                </div>
                <div className="w-full mt-2 md:mt-4">
                  <h4>{percentage}%</h4>
                  <div className="w-full bg-gray-200 rounded-full h-1.5 mt-2 md:mt-4">
                    <div className="h-1.5 rounded-full" style={{ backgroundColor: isApplicationCompleted ? "#10B981" : "#1A73E8", width: `${percentage}%` }}></div>
                  </div>
                </div>
              </div>
              {isRevoked ? (
                <div className=''>
                  <button disabled className="max-md:w-full text-sm px-4 py-2 bg-slate-400 cursor-not-allowed text-white font-semibold rounded-lg">
                    Withdrawn
                  </button>
                </div>
              ) : (
                <div className="">
                  {isApplicationCompleted ? (
                    <button onClick={() => handleClickOpen(application)} className="max-md:w-full text-sm px-4 py-2 bg-universal hover:bg-darkBlue text-white font-semibold rounded-lg">
                      Go to your Dashboard
                    </button>
                  ) : (
                    <a target='_blank' rel='noreferrer' href={paymentPortalRedirectionURL(application.university, application.tracker_id)}>
                      <button className="max-md:w-full text-sm px-4 py-2 bg-universal hover:bg-darkBlue text-white font-semibold rounded-lg">
                        Continue Application
                      </button>
                    </a>
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
      {tab?.applications.length > 2 && (
        <div className='w-full flex items-center justify-center'>
          <button onClick={handleShowAll} className='px-4 py-2 rounded-lg text-universal mx-auto mt-2'>
            <div className='flex items-center gap-1'>
              <div className={`w-5 h-5 flex-shrink-0 animate-pulse transition-all duration-200 ${isShowAll ? "rotate-180" : ""}`}>
                <LuChevronsDown className='w-full h-full' />
              </div>
              <h1>{isShowAll ? "Show Less" : "Show More"}</h1>
              <div className={`w-5 h-5 flex-shrink-0 animate-pulse transition-all duration-200 ${isShowAll ? "rotate-180" : ""}`}>
                <LuChevronsDown className='w-full h-full' />
              </div>
            </div>
          </button>
        </div>
      )}
      <ExploreProgramsModal open={isModalOpen} setOpen={setIsModalOpen} />
    </div>
  )
}

export default ApplicationAccord;

// <Accordion
//   expanded={expanded === tab.id}
//   onChange={handleAccordionChange(tab.id)}
//   disableGutters // Ensures no ripple effect
//   sx={{
//     '&:hover': {
//       backgroundColor: 'transparent !important', // Use !important to enforce no hover effect
//       boxShadow: "none !important",
//     },
//     paddingBlock: `1rem`,
//     borderRadius: '16px',
//     backgroundColor: "white",
//     border: "1px solid #dedede",
//     margin: "20px 0"
//   }}
// >
//   <AccordionSummary
//     disableRipple // Disable ripple effect on hover
//     sx={{
//       padding: "0 10px",
//       ':hover': {
//         backgroundColor: "transparent !important", // Use !important for hover styles
//         boxShadow: "none !important",
//       }
//     }}
//   >
//     <div className='w-full flex items-center justify-between gap-2'>
//       <h1 className='text-base md:text-lg font-semibold capitalize'>{tab.title}</h1>
//     </div>
//   </AccordionSummary>
//   <AccordionDetails
//     sx={{
//       padding: "0 10px"
//     }}
//   >
//     <div className="w-full flex flex-col gap-4 mt-4">
//       {tab.applications.map((application, index) => {
//         const totalSteps = getTotalSteps(application.university);
//         const percentage = (application.step / totalSteps) * 100;
//         const isApplicationCompleted = percentage === 100
//         return (
//           <div key={index} className="w-full bg-white drop-shadow-sm border flex items-center gap-8 p-8 rounded-xl">
//             <div className="flex-1">
//               <div className="w-full flex items-center justify-between">
//                 <h1 className="text-2xl font-semibold capitalize">{application.vertical}</h1>
//                 {isApplicationCompleted && application.batch_date && (
//                   <h4 className=" text-sm"><span className="font-semibold">Batch:</span> {formatDate(application.batch_date)}</h4>
//                 )}
//               </div>
//               <div className="w-full mt-4">
//                 <h4>{percentage}%</h4>
//                 <div className="w-full bg-gray-200 rounded-full h-1.5 mt-4">
//                   <div className="h-1.5 rounded-full" style={{ backgroundColor: isApplicationCompleted ? "#10B981" : "#1A73E8", width: `${percentage}%` }}></div>
//                 </div>
//               </div>
//             </div>
//             <div className="">
//               {isApplicationCompleted ? (
//                 <button onClick={() => handleClickOpen(application)} className="text-sm px-4 py-2 bg-universal hover:bg-darkBlue text-white font-semibold rounded-lg">
//                   Go to your Dashboard
//                 </button>
//               ) : (
//                 <a target='_blank' rel='noreferrer' href={`https://${application.university}.accredian.com/?app_id=${application.tracker_id}`}>
//                   <button className="text-sm px-4 py-2 bg-universal hover:bg-darkBlue text-white font-semibold rounded-lg">
//                     Continue Application
//                   </button>
//                 </a>
//               )}
//             </div>
//           </div>
//         )
//       })}
//     </div>
//   </AccordionDetails>
// </Accordion>

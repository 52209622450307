import React, { useEffect, useState } from "react";
import { Typography, Box, Paper, Grid, CardMedia, Card } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../../Navbar/MobileSidebar";
import Sidbar from "../DesktopSidebar";
import RemoveIcon from "@mui/icons-material/Remove";
import PauseIcon from "@mui/icons-material/Pause";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";

const Productstrategy = () => {
  const [user, setUser] = useState("");
  const [project, setProject] = useState("");
  let navigate = useNavigate();
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const sendData = {
    project:2,
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/productportfolio/product`,
        JSON.stringify(sendData),
        options
      )
      .then((result) => {
        setProject(result.data);
      });

  }, []);
  const handleClickOpen = (id) => {

    window.localStorage.setItem("productid", id);
    //console.log(id);
    navigate(`/Problem-statement`);
  };
  // if (user == null) {
  //  window.location.href="https://accredian.com"
  // }
  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
      <Box sx={{ pt: { lg: 8, xs: 2 }, px: { xs: 2, lg: 0 } }}>
          <Grid container>
            <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Box>
                <Paper
                  sx={{
                    ml: { lg: 3 },
                    mr: { lg: 10 },
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 2,
                    mb: 2,
                  }}
                >
                  <Grid container>
                    <Grid lg={8} xs={12}>
                      <Box sx={{ pl: 2 }}>
                        <Typography
                          sx={{
                            marginBottom: "0.5rem!important",
                            fontWeight: "bold",
                            fontSize: "2rem",
                          }}
                        >
                          Product Strategy
                        </Typography>
                        <Box display="flex">
                          <Typography
                            sx={{
                              marginBottom: "0.5rem!important",
                              color: "#a1a5b7",
                              fontSize: "0.8rem",
                            }}
                          >
                            Dashboard{" "}
                          </Typography>{" "}
                          <RemoveIcon
                            sx={{ fontSize: "1rem", mt: 0.4, color: "#a1a5b7" }}
                          />
                          <Typography
                            sx={{
                              marginBottom: "0.5rem!important",
                              color: "#a1a5b7",
                              fontSize: "0.8rem",
                            }}
                          >
                            {" "}
                            Product Portfolio{" "}
                          </Typography>{" "}
                          <RemoveIcon
                            sx={{ fontSize: "1rem", mt: 0.4, color: "#a1a5b7" }}
                          />
                          <Typography
                            sx={{
                              marginBottom: "0.5rem!important",
                              fontSize: "0.8rem",
                            }}
                          >
                             Product Strategy
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>

              <Box sx={{ ml: { lg: 2 }, mr: { lg: 10 }, mt: 5 }}>
                <Grid container>
                {project &&
          project.map((product) => (
            <Grid lg={4} md={12} sm={12} xs={12} sx={{ mb: 4 }}>
            <Card
              sx={{
                minWidth: 225,
                width: { xs: 330, lg: 290 },
                        ml: 1,
                        borderRadius: 3,
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        "&:hover": {
                          transform: "scale(1.03)",
                        },
                      }}
                      onClick={() => handleClickOpen(product.id)}
                    >
                      <Box  
                        sx={{
                          background:`${product.background_color}`,
                          
                          height: "105px",
                        }}
                      >
                        <Box>
                          <CheckIcon
                            sx={{
                              float: "right",
                              mr: 1,
                              mt: 1,
                              color: "#fff",
                              backgroundColor: "#00e64d",
                              borderRadius: 100,
                              width: "1.1em",
                              height: "1.1em",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: -3.5 }}
                      >
                        <Box
                          sx={{
                            border: "1px solid #009ef7",
                            borderRadius: "50%",
                            p: 2,
                            width: "83px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={product.logo}
                            alt="Goole"
                            sx={{
                              width: "50px",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            py: 4,
                            marginBottom: "0rem!important",
                          }}
                        >
                      {product.product_heading}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  ))}
                  {/* <Grid lg={4} sx={{mb:4}}>
                    <Card
                      sx={{
                        minWidth: 225,
                        width: 290,
                        ml: 1,
                        borderRadius: 3,
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        "&:hover": {
                          transform: "scale(1.03)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          background:
                            "linear-gradient(180deg, #4267b2 0%, rgba(110, 165, 248, 0.546875) 45.31%, rgba(119, 190, 255, 0.046875) 95.31%, rgba(92, 157, 255, 0) 100%)",
                          height: "105px",
                        }}
                      >
                        <Box>
                          <PauseIcon
                            sx={{
                              float: "right",
                              mr: 1,
                              mt: 1,
                              color: "#fff",
                              backgroundColor: "#ffaa00",
                              borderRadius: 100,
                              width: "1.3em",
                              height: "1.3em",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: -3.5 }}
                      >
                        <Box
                          sx={{
                            border: "1px solid #009ef7",
                            borderRadius: "50%",
                            p: 2,
                            width: "83px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            image="https://www.insaid.co/wp-content/uploads/2022/06/maestro.png"
                            alt="Goole"
                            sx={{
                              width: "50px",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            py: 4,
                            marginBottom: "0rem!important",
                          }}
                        >
                          Problem Statement 1
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid lg={4} sx={{mb:4}}>
                    <Card
                      sx={{
                        minWidth: 225,
                        width: 290,
                        ml: 1,
                        borderRadius: 3,
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        "&:hover": {
                          transform: "scale(1.03)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          background:
                            "linear-gradient(180deg, #4267b2 0%, rgba(110, 165, 248, 0.546875) 45.31%, rgba(119, 190, 255, 0.046875) 95.31%, rgba(92, 157, 255, 0) 100%)",
                          height: "105px",
                        }}
                      >
                        <Box>
                          <CheckIcon
                            sx={{
                              float: "right",
                              mr: 1,
                              mt: 1,
                              color: "#fff",
                              backgroundColor: "#00e64d",
                              borderRadius: 100,
                              width: "1.3em",
                              height: "1.3em",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: -3.5 }}
                      >
                        <Box
                          sx={{
                            border: "1px solid #009ef7",
                            borderRadius: "50%",
                            p: 2,
                            width: "83px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            image="https://www.insaid.co/wp-content/uploads/2022/06/maestro.png"
                            alt="Goole"
                            sx={{
                              width: "50px",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            py: 4,
                            marginBottom: "0rem!important",
                          }}
                        >
                          Problem Statement 1
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid lg={4} sx={{mb:4}}>
                    <Card
                      sx={{
                        minWidth: 225,
                        width: 290,
                        ml: 1,
                        borderRadius: 3,
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        "&:hover": {
                          transform: "scale(1.03)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          background:
                            "linear-gradient(180deg, #4267b2 0%, rgba(110, 165, 248, 0.546875) 45.31%, rgba(119, 190, 255, 0.046875) 95.31%, rgba(92, 157, 255, 0) 100%)",
                          height: "105px",
                        }}
                      >
                        <Box>
                          <CheckIcon
                            sx={{
                              float: "right",
                              mr: 1,
                              mt: 1,
                              color: "#fff",
                              backgroundColor: "#00e64d",
                              borderRadius: 100,
                              width: "1.3em",
                              height: "1.3em",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: -3.5 }}
                      >
                        <Box
                          sx={{
                            border: "1px solid #009ef7",
                            borderRadius: "50%",
                            p: 2,
                            width: "83px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            image="https://www.insaid.co/wp-content/uploads/2022/06/maestro.png"
                            alt="Goole"
                            sx={{
                              width: "50px",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            py: 4,
                            marginBottom: "0rem!important",
                          }}
                        >
                          Problem Statement 1
                        </Typography>
                      </Box>
                    </Card>
                  </Grid> */}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Productstrategy;

import { Base64 } from "js-base64";

// Universities with different total steps
const universities = [
  {
    university: "iimv",
    total_steps: 4,
  },
  {
    university: "iitg",
    total_steps: 5,
  },
  {
    university: "unknown",
    total_steps: 1,
  },
];

// Function to get total steps for a university
export const getTotalSteps = (university) => {
  const uni = universities.find((u) => u.university === university);
  return uni ? uni.total_steps : 0;
};

export function formatDate(dateString) {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Format date to "12 September 2024" in Indian time zone
  const formattedDate = new Intl.DateTimeFormat("en-IN", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }).format(date);

  return formattedDate;
}

// Function to group applications based on their completion status
export const groupApplicationsByStatus = (applications, categories) => {
  const groupedData = {
    ongoing: [],
    completed: [],
  };

  // Iterate over the applications
  applications.forEach((app) => {
    // Find the matching category from CATEGORIES array
    const categoryData = categories.find((cat) =>
      cat.category.includes(app.category)
    );

    if (categoryData) {
      // Add vertical to the application
      app.vertical = categoryData.label; // or use `value` if preferred
    } else {
      app.vertical = "Unknown"; // Default value if category is not found
    }

    const totalSteps = getTotalSteps(app.university);
    if (app.step === totalSteps && app.step_status === "complete") {
      // If current step equals total steps, mark as completed
      groupedData.completed.push(app);
    } else {
      // Otherwise, mark as ongoing
      groupedData.ongoing.push(app);
    }
  });

  return groupedData;
};

export const encodeBase64 = (base64String) => {
  try {
    let encodedString = Base64.encode(base64String);

    return encodedString;
  } catch (e) {
    console.log("error", e);
    return null;
  }
};

export const paymentPortalRedirectionURL = (university, application_id) => {
  const url =
    process.env.REACT_APP_NODE_ENV === "development"
      ? `http://localhost:5173`
      : `https://${university}.accredian.com`;

  return `${url}/?app_id=${encodeBase64(application_id)}`;
};

import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  TextField,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { industryinfo } from "../../../data/industryinfo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const WorkEducation = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategorys] = useState("");
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e4e6ef",
      },
      "&:hover fieldset": {
        borderColor: "#009ef7",
      },
    },
  });

  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/fetchprofessionaldetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      if(res.data.status=="300"){
        setData([]);
      }else{
        setData(res.data[0]);
      }
    });
  };
  const [data, setData] = useState({
    qualification: "",
    university: "",
    industry: "",
    designation: "",
    current_organization: "",
    work_experience: "",
    technology: "",
  });
  const {
    qualification,
    university,
    industry,
    designation,
    current_organization,
    work_exprience,
    technology,
  } = data;
  const datahandleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategorys(category);
    fetchData();
  }, []);
  // if (user == null) {
  //  window.location.href="https://accredian.com"
  // }

  const submitForm = async (e) => {
    e.preventDefault();
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const sandData = {
      data,
      user,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/updateprofessionaldetails`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == "200") {
          toast.success("🦄 Successful Save", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("🦄 Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  return (
    <>
      <Paper
        sx={{
          ml: 3,
          mr:{lg:10,sm:3,md:3,xs:3},
          borderRadius: "8px",
          boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
          p: 2,
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "bold" }}>Work & Education</Typography>
        </Box>
        <Box>
          <form onSubmit={submitForm}>
            <Grid container>
              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Hac"
                  label="Highest Academic Qualification"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={qualification}
                  name="qualification"
                  type="text"
                  required
                  InputLabelProps={{ shrink: true }}
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Hqi"
                  label="Highest Qualification Institution"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={university}
                  name="university"
                  type="text"
                  required
                  InputLabelProps={{ shrink: true }}
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Ci"
                  select
                  label="Current industry"
                  value={industry}
                  onChange={datahandleChange}
                  fullWidth
                  name="industry"
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  required
                  InputLabelProps={{ shrink: true }}
                >
                  {industryinfo.map((option) => (
                    <MenuItem
                      key={option.name}
                      value={option.name}
                      selected={option.name == industry}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Cd"
                  label="Current Designation"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={designation}
                  name="designation"
                  type="text"
                  required
                  InputLabelProps={{ shrink: true }}
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Co"
                  label="Current Organization"
                  placeholder=""
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={current_organization}
                  name="current_organization"
                  type="text"
                  required
                  InputLabelProps={{ shrink: true }}
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>

              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Twe"
                  label="Total Work Experience (in years)"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={work_exprience}
                  InputLabelProps={{ shrink: true }}
                  name="work_exprience"
                  type="number"
                  required
                />
              </Grid>
              <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Et"
                  label="Enter Technology area you work in"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={technology}
                  name="technology"
                  type="text"
                  required
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>

              <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    mb: 0.3,
                    float: "right",
                    mr: 3,
                    background: "#009ef7",

                    borderRadius: "8px",
                    textTransform: "none",

                    "&:hover": {
                      background: "#009ef7",

                      borderRadius: "8px",
                      textTransform: "none",
                    },
                  }}
                >
                  Save changes
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default WorkEducation;

import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Badge,
  Checkbox,
  Tabs,
  Tab,
  Step,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import useStyles from "../../../Assets/js/Projects/Projects-Style";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Sidbar from "../DesktopSidebar";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import LoginDashboard from "../../Navbar/MobileSidebar";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { orange } from "@mui/material/colors";
import axios from "axios";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));
const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};
const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,

  autoplay: true,
  autoplaySpeed: 5000,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
      },
    },
    {
      breakpoint: 600,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
      },
    },
    {
      breakpoint: 480,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
      },
    },
  ],
};
const ML1_projectId=[]

const ML1_projectId_Completed=[
]
const ML2_projectId=[]

const ML2_projectId_Completed=[
]

const ML3_projectId=[]

const ML3_projectId_Completed=[
]

const DLF_projectId=[]

const DLF_projectId_Completed=[
]
const NLP_projectId=[]

const NLP_projectId_Completed=[
]

const CV_projectId=[]

const CV_projectId_Completed=[
]
function next() {
  Slider.slickNext();
}
function previous() {
  Slider.slickPrev();
}

function Projects() {
  localStorage.removeItem("activeStep")
  let navigate = useNavigate();
  const [user, setUser] = useState("");
  const [imageData,setImageData]=useState("")
  const [current_term,setCurrentTerm]=useState(localStorage.getItem("term"))
 useEffect(()=>{
  const getComplete= async () =>{
    const sendData = {
      //  term_id: localStorage.getItem("quiz_id"),
      enrol_id:localStorage.getItem("enrol_id"),
      user_id:localStorage.getItem("user_id"),
    };
    axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/termproject/term-complete-button`,
      JSON.stringify(sendData),
      options
    )
    .then((result) => {
      if(result.data.status!=300){
        result.data.map((val)=>{
          if(val.term_id==4){
            ML1_projectId_Completed.push(val.project_id)
          }
         if(val.term_id==5){
          ML2_projectId_Completed.push(val.project_id)
         }
         if(val.term_id==6){
          ML3_projectId_Completed.push(val.project_id)
         }
         if(val.term_id==10){
          DLF_projectId_Completed.push(val.project_id)
         }
         if(val.term_id==11){
        NLP_projectId_Completed.push(val.project_id)
         }
         if(val.term_id==12){
          CV_projectId_Completed.push(val.project_id)
           }
        })
      }
    
    });
    
    // //console.log(ML1_projectId,"seee the start inside")
    
  }

  const getStart= async () =>{
    const sendData = {
      //  term_id: localStorage.getItem("quiz_id"),
      enrol_id:localStorage.getItem("enrol_id"),
      user_id:localStorage.getItem("user_id"),
    };
    axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/termproject/term-start-button`,
      JSON.stringify(sendData),
      options
    )
    .then((result) => {
      // //console.log(result.data,"see data")
      if(result.data.status!=300){
        result.data.map((val)=>{
          if(val.term_id==4){
            ML1_projectId.push(val.project_id)
          }
          if(val.term_id==5){
            ML2_projectId.push(val.project_id)
           }
           if(val.term_id==6){
            ML3_projectId.push(val.project_id)
           }
           if(val.term_id==10){
            DLF_projectId.push(val.project_id)
           }
           if(val.term_id==11){
          NLP_projectId.push(val.project_id)
           }
           if(val.term_id==12){
            CV_projectId.push(val.project_id)
             }
        })
      }
     
    });
    
    // //console.log(ML1_projectId,"seee the start inside")
    
  }
  const getTerm= async () =>{
    const sendData = {
      //  term_id: localStorage.getItem("quiz_id"),
      user_id:localStorage.getItem("user_id"),
    };
    axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/termproject/getterm`,
      JSON.stringify(sendData),
      options
    )
    .then((result) => {
      // //console.log(result,"see term")
      // if(result.data.status!=300){
      //  setCurrentTerm(res.data.term)
      setCurrentTerm(result.data)
      // }
     
    });
    
    // //console.log(ML1_projectId,"seee the start inside")
    
  }
  const getImage= async () =>{
    const sendData = {
      //  term_id: localStorage.getItem("quiz_id"),
      term_id:localStorage.getItem("term")>12?4:localStorage.getItem("term"),
    };
    axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/termproject/getimage`,
      JSON.stringify(sendData),
      options
    )
    .then((result) => {
      // //console.log(result,"image data")
     setImageData(result.data)
     
    });
    
    // //console.log(ML1_projectId,"seee the start inside")
    
  }
  getComplete()
  getStart();
  getTerm()
  getImage()
 },[])
//  //console.log(ML1_projectId,"seee the start")
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const [openMl1, setOpenMl1] = useState(false);
  const [openMl2, setOpenMl2] = useState(false);
  const [openMl3, setOpenMl3] = useState(false);
  const [openMl4, setOpenMl4] = useState(false);
  const [openMl5, setOpenMl5] = useState(false);
  const [openMl6, setOpenMl6] = useState(false);
  const [openBonus, setOpenBonus] = useState(false);
  const [openCap1, setOpenCap1] = useState(false);
  const [openCap2, setOpenCap2] = useState(false);
  const handleClickMl1 = () => {
    setOpenMl1(!openMl1);
  };
  const handleClickMl2 = () => {
    setOpenMl2(!openMl2);
  };
  const handleClickMl3 = () => {
    setOpenMl3(!openMl3);
  };
  const handleClickMl4 = () => {
    setOpenMl4(!openMl4);
  };
  const handleClickMl5 = () => {
    setOpenMl5(!openMl5);
  };
  const handleClickMl6 = () => {
    setOpenMl6(!openMl6);
  };
  const handleClickbonus = () => {
    setOpenBonus(!openBonus);
  };
  const handleClickCap = () => {
    setOpenCap1(!openCap1);
  };
  const handleClickCap2 = () => {
    setOpenCap2(!openCap2);
  };
  const Ml1_Projects = [
    "Customer Classification",
    "Predicting Bejings Air Pollution",
    "Telecom Churn Prediction",
    "Price Prediction of used Cars",
    "Abalone Age Prediction",
    "Medical Cost Prediction",
    "Predict CO2 Emission",
    "Predict Hotel Booking Cancellation",
    "Health Insurance Lead Prediction",
    "Cross Sell Prediction",
  ];
  const Ml2_Projects = [
    "Gender Recognization by Voice",
    "Drug Prediction",
    "Halloween Candy Winpercent Prediction",
    "Flight Passenger Satisfaction Prediction",
    "Avocado Price Prediction",
    "Heart Attack Analysis & Prediction",
    "Water Portability",
    "Rental Price Prediction",
    "Cancer Treatment Total Cost Prediction",
    "Diamonds Price Prediction",
  ];
  const classes = useStyles();
  useEffect(() => {
    // //console.log(auth)
    localStorage.removeItem("score_of_3")
    localStorage.removeItem("score_of_2")
    localStorage.removeItem("score_of_4")
    var user_id = localStorage.getItem("user_id");
    setUser(user_id);
  }, []);

  // if (user == null) {
  //  window.location.href="https://accredian.com"
  // }
  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: {xs:4,lg:8} }}>
          <Grid container>
            <Grid item lg={3} sx={{display:{xs:"none",lg:"block"}}}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} sm={12} md={12} xs={12}> 
              <Box>
                <Typography sx={{ fontWeight: "600", fontSize: "16px",ml:{xs:3,lg:0} }}>
                  Term Projects
                </Typography>
              </Box>
              <Box sx={{ mb: 2, }}>
                <Paper elevation={1} sx={{ p: 1.5, width: {xs:"96%",lg:"96%",sm:"96%",md:"96%"}, ml:{xs:1,lg:0} }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "19px",
                      ml: 3,
                      marginBottom: "-0.5rem !important",
                    }}
                  >
                    Term Projects
                  </Typography>
                  <Box sx={{ width: "100%", ml: 3 }}>
                    <svg
                      width="100"
                      height="4"
                      viewBox="0 0 100 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        y1="2"
                        x2="100"
                        y2="3"
                        stroke="#0064CE"
                        stroke-width="4"
                      ></line>
                    </svg>
                  </Box>
                  <Grid container>
                    <Grid item lg={6} xs={12} md={6} sm={12}>
                      <Timeline
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            marginRight: "-30px",
                          },
                        }}
                      >
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1 }}>
                            {/* <Box sx={{display:"flex"}}> */}
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                onClick={handleClickMl1}
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                    Machine Learning Foundation
                                  </Typography>
                                </ListItemText>
                                {ML1_projectId_Completed.some(number => number >= 1 && number <= 10)?(
                                  <Box
                                  sx={{
                                    background: "#47BE7D",
                                    color: "#fff",
                                    borderRadius: "6px",
                                    pl: 1,
                                    pr: 1,
                                    height: "fit-content",
                                    fontSize: "0.8rem",
                                    width: "80px !important",
                                    textAlign: "center",
                                    marginBottom: "15px !important",
                                  }}
                                  >
                                  Completed
                                  </Box>
                                ):(
                                  <Box>
                                    {ML1_projectId.some(number => number >= 1 && number <= 10)?(
                                       <Box
                                       sx={{
                                         background: "#009ef7",
                                         color: "#fff",
                                         borderRadius: "6px",
                                         pl: 1,
                                         pr: 1,
                                         height: "fit-content",
                                         fontSize: "0.8rem",
                                         width: "80px !important",
                                         textAlign: "center",
                                       ml:{xs:0,lg:3},
                                       mt:{xs:0.5,lg:0},
                                         marginBottom: "15px !important",
                                       }}
                                     >
                                       Started
                                     </Box>
                                    ):(
                                       <Box
                                       sx={{
                                         background: "#FFA87D",
                                         color: "#fff",
                                         borderRadius: "6px",
                                         pl: 1,
                                         pr: 1,
                                         height: "fit-content",
                                         fontSize: "0.8rem",
                                         width: "80px !important",
                                         textAlign: "center",
                                         marginBottom: "15px !important",
                                       }}
                                     >
                                       Pending
                                     </Box>
                                    )}
                                  </Box>
                                 
                                )}
                               
                                {/* {openMl1 ? <ExpandLess /> : <ExpandMore />} */}
                              </ListItemButton>

                              <Collapse
                                in={openMl1}
                                timeout="auto"
                                unmountOnExit
                              >
                                {/* {Ml1_Projects.map((val)=>( */}

                                <List component="div" disablePadding>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "0px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/customer-classification"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Customer Classification
                                        </Typography>
                                      </Link>
                                    </ListItemText>
                                    {ML1_projectId_Completed.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
  
                                        mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                      ml:{xs:0,lg:3},
                                      mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml:{xs:0,lg:3},
                                        mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                    
                                    
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/predicting-air-pollution"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Predicting Bejings Air Pollution
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML1_projectId_Completed.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
 
                                        mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
 
                                        mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
  
                                        mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/telecom-churn-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Telecom Churn Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML1_projectId_Completed.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
  mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
  mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
  mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/price-prediction-of-used-car"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Price Prediction of used Cars
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML1_projectId_Completed.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
  mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
  mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
  mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/abalone-age-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Abalone Age Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML1_projectId_Completed.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/medical-cost-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Medical Cost Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML1_projectId_Completed.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/predict-c02-emission"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Predict CO2 Emission
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML1_projectId_Completed.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/predicting-hotel-booking-cancellation"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Predict Hotel Booking Cancellation
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML1_projectId_Completed.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/health-insurance-lead-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Health Insurance Lead Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML1_projectId_Completed.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/cross-sell-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Cross Sell Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML1_projectId_Completed.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML1_projectId.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                         ml: {xs:0,lg:3},
   mt:{xs:0.5,lg:0},
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                </List>

                                {/* ))} */}
                              </Collapse>
                            </List>

                            {/* </Box> */}
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1, display: "flex" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                onClick={handleClickMl2}
                                disabled={current_term>=5?false:true}
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                    Machine Learning Intermediate
                                  </Typography>
                                </ListItemText>
                                {ML2_projectId_Completed.some(number => number >= 1 && number <= 10)?(
                                  <Box
                                  sx={{
                                    background: "#47BE7D",
                                    color: "#fff",
                                    borderRadius: "6px",
                                    pl: 1,
                                    pr: 1,
                                    height: "fit-content",
                                    fontSize: "0.8rem",
                                    width: "80px !important",
                                    textAlign: "center",
                                    marginBottom: "15px !important",
                                  }}
                                  >
                                  Completed
                                  </Box>
                                ):(
                                  <Box>
                                  {ML2_projectId.some(number => number >= 1 && number <= 10)?(
                                     <Box
                                     sx={{
                                       background: "#009ef7",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                     ml:{xs:0,lg:3},
                                     mt:{xs:0.5,lg:0},
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Started
                                   </Box>
                                  ):(
                                     <Box
                                     sx={{
                                       background: "#FFA87D",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Pending
                                   </Box>
                                  )}
                                </Box>
                                )}
                                {/* {openMl2 ? <ExpandLess /> : <ExpandMore />} */}
                              </ListItemButton>
                              <Collapse
                                in={openMl2}
                                timeout="auto"
                                unmountOnExit
                              >
                                {/* {Ml2_Projects.map((val) => (
                                  <List component="div" disablePadding>
                                    <ListItemButton
                                      sx={{
                                        pl: 4,
                                        paddingRight: "0px !important",
                                      }}
                                    >
                                      <ListItemText
                                        sx={{ marginTop: "15px !important" }}
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          {val}
                                        </Typography>
                                      </ListItemText>
                                      <Box
                                        sx={{
                                          background: "#009ef7",
                                          color: "#fff",
                                          borderRadius: "6px",
                                          pl: 1,
                                          pr: 1,
                                          height: "fit-content",
                                          fontSize: "0.8rem",
                                          width: "80px !important",
                                          textAlign: "center",
                                          ml: 3,
                                          marginBottom: "15px !important",
                                        }}
                                      >
                                        started
                                      </Box>
                                    </ListItemButton>
                                  </List>
                                ))} */}
                                 <List component="div" disablePadding>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/gender-recogination-by-voice"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Gender Recognization by Voice
                                        </Typography>
                                      </Link>
                                    </ListItemText>
                                    {ML2_projectId_Completed.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                    
                                    
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/drug-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Drug Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML2_projectId_Completed.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/candy-winpercent-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Halloween Candy Winpercent Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML2_projectId_Completed.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/flight-passenger-satisfaction-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                        Flight Passenger Satisfaction Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML2_projectId_Completed.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/avacado-price-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Avocado Price Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML2_projectId_Completed.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/heart-attack"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Heart Attack Analysis & Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML2_projectId_Completed.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/water-portability"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Water Portability
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML2_projectId_Completed.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/rental-price-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Rental Price Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML2_projectId_Completed.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/cancer-treatment-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Cancer Treatment Total Cost Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML2_projectId_Completed.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                      pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/diamond-price-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Diamonds Price Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML2_projectId_Completed.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML2_projectId.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                </List>

                              </Collapse>
                            </List>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem> 
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                             <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1, display: "flex" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                onClick={handleClickMl3}
                                disabled={current_term>=6?false:true}
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                    Machine Learning Advanced
                                  </Typography>
                                </ListItemText>
                                {ML3_projectId_Completed.some(number => number >= 1 && number <= 10)?(
                                  <Box
                                  sx={{
                                    background: "#47BE7D",
                                    color: "#fff",
                                    borderRadius: "6px",
                                    pl: 1,
                                    pr: 1,
                                    height: "fit-content",
                                    fontSize: "0.8rem",
                                    width: "80px !important",
                                    textAlign: "center",
                                    marginBottom: "15px !important",
                                  }}
                                  >
                                  Completed
                                  </Box>
                                ):(
                                  <Box>
                                  {ML3_projectId.some(number => number >= 1 && number <= 10)?(
                                     <Box
                                     sx={{
                                       background: "#009ef7",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                     ml:{xs:0,lg:3},
                                     mt:{xs:0.5,lg:0},
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Started
                                   </Box>
                                  ):(
                                     <Box
                                     sx={{
                                       background: "#FFA87D",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Pending
                                   </Box>
                                  )}
                                </Box>
                                )}
                                {/* {openMl3 ? <ExpandLess /> : <ExpandMore />} */}
                              </ListItemButton>
                              <Collapse
                                in={openMl3}
                                timeout="auto"
                                unmountOnExit
                              >
                                {/* {Ml2_Projects.map((val)=>( */}

                                <List component="div" disablePadding>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/concrete-compressive-strength-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Concrete Compressive Strenght Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>
                                    {ML3_projectId_Completed.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                    
                                    
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/solar-radiation"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Solar Radiation Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML3_projectId_Completed.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/legendary-pokemon-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Legendry Pokeman Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML3_projectId_Completed.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/human-activity-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Human Activity Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML3_projectId_Completed.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/seattle-weather-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Seattle Weather Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML3_projectId_Completed.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/employee-access-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Employee Access Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML3_projectId_Completed.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/crude-oil-prediction-state"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Crud Oil Prediction State
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML3_projectId_Completed.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/wind-turbine-power-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Wind Turbine Power Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML3_projectId_Completed.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/reducing-marketing-waste"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Reduce Marketing Waste
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML3_projectId_Completed.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/world-happiness-report"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                       World Happiness Report
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {ML3_projectId_Completed.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {ML3_projectId.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                </List>

                                {/* ))} */}
                              </Collapse>
                            </List>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem> 
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                             <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1, display: "flex" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                onClick={handleClickMl4}
                                disabled={current_term>=10?false:true}
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                    Deep Learning Foundation
                                  </Typography>
                                </ListItemText>
                                {DLF_projectId_Completed.some(number => number >= 1 && number <= 10)?(
                                  <Box
                                  sx={{
                                    background: "#47BE7D",
                                    color: "#fff",
                                    borderRadius: "6px",
                                    pl: 1,
                                    pr: 1,
                                    height: "fit-content",
                                    fontSize: "0.8rem",
                                    width: "80px !important",
                                    textAlign: "center",
                                    marginBottom: "15px !important",
                                  }}
                                  >
                                  Completed
                                  </Box>
                                ):(
                                  <Box>
                                  {DLF_projectId.some(number => number >= 1 && number <= 10)?(
                                     <Box
                                     sx={{
                                       background: "#009ef7",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                     ml:{xs:0,lg:3},
                                     mt:{xs:0.5,lg:0},
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Started
                                   </Box>
                                  ):(
                                     <Box
                                     sx={{
                                       background: "#FFA87D",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Pending
                                   </Box>
                                  )}
                                </Box>
                                )}
                                {/* {openMl3 ? <ExpandLess /> : <ExpandMore />} */}
                              </ListItemButton>
                              <Collapse
                                in={openMl4}
                                timeout="auto"
                                unmountOnExit
                              >
                                {/* {Ml2_Projects.map((val)=>( */}

                                <List component="div" disablePadding>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/rainfall-prediction-in-australia"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Rainfall Prediction in Australia
                                        </Typography>
                                      </Link>
                                    </ListItemText>
                                    {DLF_projectId_Completed.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                    
                                    
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/defcon-level-classification"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                       DEFCON Level Classification
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {DLF_projectId_Completed.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/air-quality-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                        Air Quality Index Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {DLF_projectId_Completed.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/melanoma-tumor-size-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Melanoma Tumor Size Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {DLF_projectId_Completed.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/slashing-price-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                        Slashing Price Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {DLF_projectId_Completed.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/term-deposit-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                        Term Deposit Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {DLF_projectId_Completed.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/predict-length-of-stay-of-patients"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                          Predict Length of Stay of Patients
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {DLF_projectId_Completed.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/predict-unit-price-predict"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                        Predict Unit Price of Products
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {DLF_projectId_Completed.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/surge-pricing-type-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                        Surge Pricing Type Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {DLF_projectId_Completed.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/advertisement-success-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                    Advertisement Success Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {DLF_projectId_Completed.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {DLF_projectId.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                </List>

                                {/* ))} */}
                              </Collapse>
                            </List>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem> 
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                             <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1, display: "flex" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                onClick={handleClickMl5}
                                disabled={current_term>=11?false:true}
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                   Natural Language Processing
                                  </Typography>
                                </ListItemText>
                                {NLP_projectId_Completed.some(number => number >= 1 && number <= 10)?(
                                  <Box
                                  sx={{
                                    background: "#47BE7D",
                                    color: "#fff",
                                    borderRadius: "6px",
                                    pl: 1,
                                    pr: 1,
                                    height: "fit-content",
                                    fontSize: "0.8rem",
                                    width: "80px !important",
                                    textAlign: "center",
                                    marginBottom: "15px !important",
                                  }}
                                  >
                                  Completed
                                  </Box>
                                ):(
                                  <Box>
                                  {NLP_projectId.some(number => number >= 1 && number <= 10)?(
                                     <Box
                                     sx={{
                                       background: "#009ef7",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                     ml:{xs:0,lg:3},
                                     mt:{xs:0.5,lg:0},
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Started
                                   </Box>
                                  ):(
                                     <Box
                                     sx={{
                                       background: "#FFA87D",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Pending
                                   </Box>
                                  )}
                                </Box>
                                )}
                                {/* {openMl3 ? <ExpandLess /> : <ExpandMore />} */}
                              </ListItemButton>
                              <Collapse
                                in={openMl5}
                                timeout="auto"
                                unmountOnExit
                              >
                                {/* {Ml2_Projects.map((val)=>( */}

                                <List component="div" disablePadding>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/whether-real-or-fake-news"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                        Classify whether Real or Fake News
                                        </Typography>
                                      </Link>
                                    </ListItemText>
                                    {NLP_projectId_Completed.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                    
                                    
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/subject-text-classification"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                      Subject Text Classification
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {NLP_projectId_Completed.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/predicting-topic-of-the-news"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                      Predicting the Topic of the News
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {NLP_projectId_Completed.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/news-group-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                        Newsgroup Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {NLP_projectId_Completed.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/coronavirus-tweets-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                       Coronavirus tweets classification
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {NLP_projectId_Completed.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/sms-spam-classification"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                       SMS Spam classification
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {NLP_projectId_Completed.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/disaster-tweet-classification"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Disaster Tweet Classification
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {NLP_projectId_Completed.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/trip-advisor-hotel-review"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                       Trip Advisor Hotel Reviews
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {NLP_projectId_Completed.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/emotion-detect-analysis"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                      Emotions Detect Analysis
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {NLP_projectId_Completed.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  {/* <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/advertisement-success-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                    Advertisement Success Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {NLP_projectId_Completed.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {NLP_projectId.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton> */}
                                </List>

                                {/* ))} */}
                              </Collapse>
                            </List>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem> 
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                             {/* <TimelineConnector /> */}
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1, display: "flex" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                onClick={handleClickMl6}
                                disabled={current_term>=12?false:true}
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                  Computer Vision
                                  </Typography>
                                </ListItemText>
                                {CV_projectId_Completed.some(number => number >= 1 && number <= 10)?(
                                  <Box
                                  sx={{
                                    background: "#47BE7D",
                                    color: "#fff",
                                    borderRadius: "6px",
                                    pl: 1,
                                    pr: 1,
                                    height: "fit-content",
                                    fontSize: "0.8rem",
                                    width: "80px !important",
                                    textAlign: "center",
                                    marginBottom: "15px !important",
                                  }}
                                  >
                                  Completed
                                  </Box>
                                ):(
                                  <Box>
                                  {CV_projectId.some(number => number >= 1 && number <= 10)?(
                                     <Box
                                     sx={{
                                       background: "#009ef7",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                     ml:{xs:0,lg:3},
                                     mt:{xs:0.5,lg:0},
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Started
                                   </Box>
                                  ):(
                                     <Box
                                     sx={{
                                       background: "#FFA87D",
                                       color: "#fff",
                                       borderRadius: "6px",
                                       pl: 1,
                                       pr: 1,
                                       height: "fit-content",
                                       fontSize: "0.8rem",
                                       width: "80px !important",
                                       textAlign: "center",
                                       marginBottom: "15px !important",
                                     }}
                                   >
                                     Pending
                                   </Box>
                                  )}
                                </Box>
                                )}
                                {/* {openMl3 ? <ExpandLess /> : <ExpandMore />} */}
                              </ListItemButton>
                              <Collapse
                                in={openMl6}
                                timeout="auto"
                                unmountOnExit
                              >
                                {/* {Ml2_Projects.map((val)=>( */}

                                <List component="div" disablePadding>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/natural-scene-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                         Natural Scenes Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>
                                    {CV_projectId_Completed.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(1)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                    
                                    
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/predicting-asl-signs"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                     Predicting ASL Signs
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {CV_projectId_Completed.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(2)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/flower-species-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                       Flower Species Prediction
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {CV_projectId_Completed.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(3)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/monkey-species-prediction"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                       Predicting Monkey Species
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {CV_projectId_Completed.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(4)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/presence-of-ship-in-satellite-imagery"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                       Presence of Ships in Satellite Imagery
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {CV_projectId_Completed.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(5)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/skin-cancer-detection"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                      Skin Cancer Detection
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {CV_projectId_Completed.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(6)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/face-mask-detection"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                        Face Mask Detection
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {CV_projectId_Completed.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(7)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/tomato-leaf-disease-classification"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                      Tomato Leaf Disease Classification
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {CV_projectId_Completed.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(8)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/gender-classificaton-using-image"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                       Gender classification using Image
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {CV_projectId_Completed.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(9)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{
                                           pl:{lg:4,xs:0,sm:1,md:1},
                                      paddingRight: "0px !important",
                                      height: "35px !important",
                                      display:{xs:"flex",lg:"flex"}
                                    }}
                                  >
                                    <ListItemText
                                      sx={{ marginTop: "15px !important" }}
                                    >
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        to="/Term-projects/fruits-360-classification"
                                      >
                                        <Typography
                                          sx={{ fontSize: "13px !important" }}
                                        >
                                   Fruits 360 Classification
                                        </Typography>
                                      </Link>
                                    </ListItemText>

                                    {CV_projectId_Completed.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#47BE7D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Completed
                                    </Box>
                                    </Box>):(<Box>
                                      {CV_projectId.includes(10)?(<Box>
                                      <Box
                                      sx={{
                                        background: "#009ef7",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Started
                                    </Box>
                                    </Box>):(
                                      <Box>
                                        <Box
                                      sx={{
                                        background: "#FFA87D",
                                        color: "#fff",
                                        borderRadius: "6px",
                                        pl: 1,
                                        pr: 1,
                                        height: "fit-content",
                                        fontSize: "0.8rem",
                                        width: "80px !important",
                                        textAlign: "center",
                                        ml: 3,
                                        marginBottom: "15px !important",
                                      }}
                                    >
                                      Pending
                                    </Box>
                                      </Box>
                                    )}
                                    </Box>)}
                                  </ListItemButton>
                                </List>

                                {/* ))} */}
                              </Collapse>
                            </List>
                          </TimelineContent>
                        </TimelineItem>

                      </Timeline>
                    </Grid>
                    <Grid lg={6} xs={12} md={6} sm={12} className={classes.imagsilder} sx={{display:{xs:"none",lg:"block"}}}>
                      <Slider ref={sliderRef} {...settings}>
                        {imageData&&imageData.map((val)=>(
                           <Grid lg={12} xs={12}>
                           <img
                             src={val.image_url}
                             className={classes.images}
                           />
                           <Box sx={{ ml: 9, mt: 1.5 }}>
                             <Typography
                               sx={{
                                 fontWeight: "bold",
                                 fontSize: "16px",
                                 marginBottom: "0.3rem !important",
                               }}
                             >
                             {val.project_name}  
                             </Typography>
                             <Typography sx={{ fontSize: "14px" }}>
                             {val.Description}
                             </Typography>
                           </Box>
                         </Grid>
                        )

                        )}
                       
                      </Slider>
                      <Button
                        className={classes.slbuttonprv}
                        onClick={gotoPrev}
                      >
                        <ArrowBackIosIcon
                          sx={{ color: "#000" }}
                        ></ArrowBackIosIcon>
                      </Button>
                      <Button
                        className={classes.slbuttonnext}
                        onClick={gotoNext}
                      >
                        <ArrowForwardIosIcon
                          sx={{ color: "#000" }}
                        ></ArrowForwardIosIcon>
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Box sx={{ mt: 1, mb: 2 }}>
                <Paper elevation={1} sx={{ p: 1.5, width: {xs:"96%",lg:"96%"}, ml:{xs:1,lg:0} }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "19px",
                      ml: 3,
                      marginBottom: "-0.5rem !important",
                    }}
                  >
                    Bonus Term Projects
                  </Typography>
                  <Box sx={{ width: "100%", ml: 3 }}>
                    <svg
                      width="100"
                      height="4"
                      viewBox="0 0 100 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        y1="2"
                        x2="100"
                        y2="3"
                        stroke="#0064CE"
                        stroke-width="4"
                      ></line>
                    </svg>
                  </Box>
                  {/* <Grid container>
                    <Grid item lg={6} xs={12}>
                      <Timeline
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            marginRight: "-30px",
                          },
                        }}
                      >
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                           
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1, display: "flex" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                onClick={handleClickbonus}
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                    Machine Learning Bonus
                                  </Typography>
                                </ListItemText>
                               
                              </ListItemButton>
                              <Collapse
                                in={openBonus}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary="Starred" />
                                  </ListItemButton>
                                </List>
                              </Collapse>
                            </List>
                            <Box
                              sx={{
                                background: "#47BE7D",
                                color: "#fff",
                                borderRadius: "6px",
                                pl: 1,
                                pr: 1,
                                height: "fit-content",
                                fontSize: "0.8rem",
                                width: "80px !important",
                                textAlign: "center",
                              }}
                            >
                              Completed
                            </Box>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Grid>
                    <Grid lg={6} xs={12} className={classes.imagsilder}></Grid>
                  </Grid> */}
                   <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        ml:3,
                        mt:2,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"430px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      {/* <NoteAltIcon sx={{ fontSize: "1.3rem", mr: 2 }} /> */}
                     Machine Learning Bonus
                      <LightTooltip
                        title="Exciting updates coming soon for this feature!"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 6,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip>
                    </Typography>
                </Paper>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Paper elevation={1} sx={{ p: 1.5, width: {xs:"96%",lg:"96%"}, ml:{xs:1,lg:0}}}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "19px",
                      ml: 3,
                      marginBottom: "0.1rem !important",
                    }}
                  >
                    Capstone Projects
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      ml: 3,
                      marginBottom: "-0.5rem !important",
                    }}
                  >
                    Select specific term project and complete it.
                  </Typography>
                  <Box sx={{ width: "100%", ml: 3 }}>
                    <svg
                      width="100"
                      height="4"
                      viewBox="0 0 100 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        y1="2"
                        x2="100"
                        y2="3"
                        stroke="#0064CE"
                        stroke-width="4"
                      ></line>
                    </svg>
                  </Box>
                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      <Timeline
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            marginRight: "-30px",
                          },
                        }}
                      >
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1, display: "flex" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                // onClick={handleClickCap}
                                href="https://projects.accredian.com/capstone1/login.php"
                                target="_blank"
                                
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                    Capstone 1
                                  </Typography>
                                </ListItemText>
                                {/* {openCap1 ? <ExpandLess /> : <ExpandMore />} */}
                              </ListItemButton>
                              {/* <Collapse
                                in={openCap1}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary="Starred" />
                                  </ListItemButton>
                                </List>
                              </Collapse> */}
                            </List>
                            {/* <Box
                              sx={{
                                background: "#47BE7D",
                                color: "#fff",
                                borderRadius: "6px",
                                pl: 1,
                                pr: 1,
                                height: "fit-content",
                                fontSize: "0.8rem",
                                width: "80px !important",
                                textAlign: "center",
                              }}
                            >
                              Completed
                            </Box> */}
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1, display: "flex" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                href="https://projects.accredian.com/capstone2https://frontend-project2.accredian.com/login.php"
                                target="_blank"
                                disabled={current_term>=6?false:true}
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                    Capstone 2
                                  </Typography>
                                </ListItemText>
                                {/* {openCap2 ? <ExpandLess /> : <ExpandMore />} */}
                              </ListItemButton>
                              {/* <Collapse
                                in={openCap2}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary="Starred" />
                                  </ListItemButton>
                                </List>
                              </Collapse> */}
                            </List>
                            {/* <Box
                              sx={{
                                background: "#47BE7D",
                                color: "#fff",
                                borderRadius: "6px",
                                pl: 1,
                                pr: 1,
                                height: "fit-content",
                                fontSize: "0.8rem",
                                width: "80px !important",
                                textAlign: "center",
                              }}
                            >
                              Completed
                            </Box> */}
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                //   margin: "7.5px 0",
                                //   padding: "4px",
                                backgroundColor: "#00B5B8",
                              }}
                            />
                            {/* <TimelineConnector /> */}
                          </TimelineSeparator>
                          <TimelineContent sx={{ ml: 1, display: "flex" }}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                padding: "0px !important",
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                            >
                              <ListItemButton
                                sx={{ padding: "0 !important" }}
                                href="https://projects.accredian.com/capstone3/"
                                target="_blank"
                                disabled={current_term>=12?false:true}
                              >
                                <ListItemText
                                  sx={{
                                    marginTop: "0px !important",
                                    fontSize: "14px !important",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "14px !important" }}
                                  >
                                    Capstone 3
                                  </Typography>
                                </ListItemText>
                                {/* {openCap2 ? <ExpandLess /> : <ExpandMore />} */}
                              </ListItemButton>
                              {/* <Collapse
                                in={openCap2}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemText primary="Starred" />
                                  </ListItemButton>
                                </List>
                              </Collapse> */}
                            </List>
                            {/* <Box
                              sx={{
                                background: "#47BE7D",
                                color: "#fff",
                                borderRadius: "6px",
                                pl: 1,
                                pr: 1,
                                height: "fit-content",
                                fontSize: "0.8rem",
                                width: "80px !important",
                                textAlign: "center",
                              }}
                            >
                              Completed
                            </Box> */}
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Grid>
                    <Grid lg={6} xs={12} className={classes.imagsilder}></Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
export default Projects;

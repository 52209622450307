import api from "./server";

export const fetchCategoriesService = async () => {
  try {
    const { data } = await api.get(`/program/fetch-categories`);

    return data;
  } catch (error) {
    throw error;
  }
};
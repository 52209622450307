import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Gender = [
  {
    value: "Select your Gender",
    label: "Select your Gender",
  },
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];
const ProfileDetails = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [code, setCode] = useState("");
  const [statecode, setCodes] = useState("");
  const [city, setCity] = useState("");
  const [country, setContryname] = useState("");
  const [state, setStatename] = useState("");
  const [user_id, setUser] = useState("");
  const [username, setUsername] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategorys] = useState("");
  // Use for Navigate on Previous
  let navigate = useNavigate();
  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/fetchuserdetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
   
        setData(res.data[0]);
        setCity(res.data[0].city);
        setContryname(res.data[0].country);
        setStatename(res.data[0].state);
        setUsername(res.data[0].firstname);
        //console.log(res.data[0].firstname);
       
      const arr = Country.getAllCountries();
        const nsate = State.getAllStates();
      for (var i = 0; i < arr.length; i++) {
        if (res.data[0].country === arr[i].name) {
          setCode(arr[i].isoCode);
          break;
        }
      };
      
      for (var j = 0; j < nsate.length; j++) {
       
        if (res.data[0].state === nsate[j].name) {
          //console.log(nsate[j].name);
          setCodes(nsate[j].isoCode);
          break;
        }
      }
    });
  };
  
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    dob: "",
    gender: "",
    address1: "",
    address2: "",
    pincode: "",
  });
  const {
    firstname,
    lastname,
    email,
    mobile,
    dob,
    gender,
    address1,
    address2,
    pincode,
  } = data;
  const datahandleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategorys(category);
    fetchData();

  }, []);
  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));

        setCountries(allCountries);
        setSelectedCountry(code);
      } catch (error) {
        setCountries([]);
      }
    };

    getCountries();
  }, [code]);

  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));

        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(statecode);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };

    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(
          selectedCountry,
          selectedState
        );
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));

        setCities(allCities);
        setSelectedCity(city);
      } catch (error) {
        setCities([]);
      }
    };

    getCities();
  }, [selectedState]);

  const submitForm = async (e) => {
    e.preventDefault();
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      ...data,
      country,
      state,
      city,
      user_id,
    };
    await axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/updateuserdetails`,
      JSON.stringify(sandData),
      options
    )
    .then((result) => {
      if (result.data.status == "200") {
        toast.success("Successful Save", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Error", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
    //console.log(sandData);
  };
  // if (user_id == null) {
  //  window.location.href="https://accredian.com"
  // }
  return (
    <Paper
      sx={{
        ml: 3,
        mr:{lg:10,sm:3,md:3,xs:3},
        borderRadius: "8px",
        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
        p: 2,
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>Profile Details</Typography>
      </Box>
      <form onSubmit={submitForm}>
        <Box>
          <Grid container>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="First_Name"
                label="First Name"
                name="firstname"
                fullWidth
                type="text"
                sx={{ pr:{lg:3,sm:3,md:3,xs:0}}}
                onChange={datahandleChange}
                value={firstname}
                required
                onKeyPress={(e) => {
                  if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Last_Name"
                label="Last Name"
                name="lastname"
                fullWidth
                type="text"
                sx={{ pr:{lg:3,sm:3,md:3,xs:0}}}
                onChange={datahandleChange}
                value={lastname}
                required
                onKeyPress={(e) => {
                  if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="E-mail"
                label="E-mail"
                name="email"
                fullWidth
                type="email"
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={email}
                required
                InputProps={{
                  readOnly: true, 
                }}
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Phone"
                label="Phone"
                name="mobile"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={mobile}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Birth date"
                label="Birth date"
                name="dob"
                type="date"
                placeholder=""
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                InputLabelProps={{ shrink: true }}
                onChange={datahandleChange}
                value={dob}
                data-date=""
                data-date-format="DD/MM/YYYY"
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="gender"
                select
                label="Gender"
                onChange={datahandleChange}
                value={gender}
                name="gender"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                InputLabelProps={{ shrink: true }}
              >
                {Gender.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    selected={option.value == gender}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Addressone"
                label="Address Line 1"
                name="address1"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={address1}
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Addresstwo"
                label="Address Line 2"
                name="address2"
                fullWidth
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={address2}
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>

            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                select
                id="Country"
                label="Country"
                name="country"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={(event) => {setSelectedCountry(event.target.value);
                }}
                value={selectedCountry}
              >
                {countries.map(({ isoCode, name }) => (
                  <MenuItem
                    value={isoCode}
                    key={isoCode}
                    onClick={() => {
                      setContryname(name);
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                select
                id="State"
                label="State"
                name="state"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={(event) => setSelectedState(event.target.value)}
                value={selectedState}
              >
                {states.length > 0 ? (
                  states.map(({ isoCode, name }) => (
                    <MenuItem
                      value={isoCode}
                      key={isoCode}
                      onClick={() => {
                        setStatename(name);
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" key="">
                    No state found
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                select
                id="City"
                label="City"
                name="city"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={(event) => setSelectedCity(event.target.value)}
                value={selectedCity}
              >
                {cities.length > 0 ? (
                  cities.map(({ name }) => (
                    <MenuItem
                      value={name}
                      key={name}
                      onClick={() => {
                        setCity(name);
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No cities found</MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Pincode"
                label="Pincode"
                name="pincode"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={pincode}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={6} sx={{ mb: 3 }}>
              <Typography sx={{ fontSize: "0.8rem" }}>
                <b>Note:</b> Fields are mandatory
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={6} sx={{ mb: 3 }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  mb: 0.3,
                  float:{lg: "right",sm:"right",md: "right"},
                  mr: 3,
                  background: "#009ef7",

                  borderRadius: "8px",
                  textTransform: "none",

                  "&:hover": {
                    background: "#009ef7",

                    borderRadius: "8px",
                    textTransform: "none",
                  },
                }}
              >
                Save changes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Paper>
  );
};
export default ProfileDetails;

import { MdArrowOutward } from "react-icons/md";

export const ImageWithFallback = ({ src, alt, fallbackSrc, ...props }) => {
  const handleError = (e) => {
    e.target.onerror = null;
    e.target.src = fallbackSrc;
  };

  return <img src={src} alt={alt} onError={handleError} {...props} />;
};

export const ProgramCard = ({ item, handleClickOpen }) => {

  return (
    <div className='w-full max-w-sm sm:min-h-[55vh] rounded-2xl overflow-hidden bg-white drop-shadow-sm border relative'>
      <div className="program-card-container sm:block hidden" />
      <div className="w-full sm:absolute bg-white bottom-0 left-0 right-0 p-5">
        <div className="w-full min-h-[25vh] flex flex-col justify-between">
          <div className="w-full min-h-20 text-center flex flex-col items-center">
            <div className="w-24 h-24 flex-shrink-0 bg-slate-50 rounded-xl sm:-mt-16 p-4 border-2 border-white">
              <ImageWithFallback
                src={"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/icon-3.png"}
                alt={item.program_code}
                fallbackSrc="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/icon-3.png"
                className="w-full h-full object-cover"
              />
            </div>
            <h1 className="text-lg font-semibold mt-6">{item.program_name}</h1>
          </div>
          <button
            onClick={() => handleClickOpen(item)}
            className="w-full px-4 py-2.5 text-xs font-semibold border rounded-lg text-center bg-universal flex justify-between gap-1 items-center text-white hover:bg-darkBlue transition-all duration-200 text-nowrap mt-4"
          >
            Start Learning
            <MdArrowOutward />
          </button>
        </div>
      </div>
    </div>
  );
};

